import React, { Component } from "react";
import FPGA from "./sim/fpga";
import * as read_modules from "./sim/read_modules";
import * as modules from "./sim/modules";
import "./DrongoSim.css";
import {Helmet} from 'react-helmet-async';

let top_level;
let ucf_filename;
let g_inputs = [];
let g_outputs = [];
let g_ops = [];
let g_ucf = {};
let clk_id = -1;
let all_schematics = [];
let CLK = 0;
// let AN0_id = -1;
// let AN1_id = -1;
// let AN2_id = -1;
// let AN3_id = -1;
// let AN_state = [1,1,1,1];

// Enable the passage of the 'this' object through the JavaScript timers

var __nativeST__ = window.setTimeout, __nativeSI__ = window.setInterval;

window.setTimeout = function (vCallback, nDelay /*, argumentToPass1, argumentToPass2, etc. */) {
  var oThis = this, aArgs = Array.prototype.slice.call(arguments, 2);
  return __nativeST__(vCallback instanceof Function ? function () {
    vCallback.apply(oThis, aArgs);
  } : vCallback, nDelay);
};

window.setInterval = function (vCallback, nDelay /*, argumentToPass1, argumentToPass2, etc. */) {
  var oThis = this, aArgs = Array.prototype.slice.call(arguments, 2);
  return __nativeSI__(vCallback instanceof Function ? function () {
    vCallback.apply(oThis, aArgs);
  } : vCallback, nDelay);
};

function throw_error(msg) {
  const message = document.getElementById("log");
  message.innerHTML = "> "+msg;
  throw msg;
}

function find_top_level(file, Files, prev) {
  var reader = new FileReader();
  reader.onload = function(event) {
      var contents = event.target.result;
      // let re = /\"Implementation Top File\" xil_pn:value=\"(.+)\" xil/;
      let re = /"Implementation Top File" xil_pn:value="(.+)" xil/;
      let ret = contents.match(re);
      // console.log(ret);
      if (ret === null) {
        throw_error("Could not find top level module in .xise file.");
        //throw "Could not find top level module in .xise file.";
      }
      // for (let i = 0; i < Files.length; i++) {
      //   if (Files[i].name === ret[1])
      // }
      top_level = ret[1];
      re = /"Target UCF File Name" xil_pn:value="(.+)" xil/;
      ret = contents.match(re);
      // console.log(ret);
      if (ret === null) {
        throw_error("Could not find ucf file in .xise file.");
        //throw "Could not find top level module in .xise file.";
      }
      // for (let i = 0; i < Files.length; i++) {
      //   if (Files[i].name === ret[1])
      // }
      ucf_filename = ret[1];

      let schematics = [];
      for (let i = 0; i < Files.length; i++) {
        if (Files[i].name.endsWith(".sch")) {
          schematics.push(i);
        }
      }
      read_schematics(schematics, Files, prev);
      // interpret_files(ret[1], Files, prev);
  };
  reader.onerror = function(event) {
      console.error("File could not be read! Code " + event.target.error.code);
  };
  reader.readAsText(file);
}

function read_schematics(schematics, Files, prev) {
  var reader = new FileReader();
  let name;
  reader.onload = function(event) {
      var contents = event.target.result;
      all_schematics.push({"name":name, "contents":contents});
      // console.log(name);
      read_schematics(schematics, Files, prev);
  };
  reader.onerror = function(event) {
      console.error("File could not be read! Code " + event.target.error.code);
  };
  if (schematics.length === 0) {
    interpret_files(top_level, Files, prev);
    return;
  }
  // console.log(schematics);
  // console.log(Files);
  let next = Files[schematics.shift()];
  // console.log(next);
  name = next.name;
  reader.readAsText(next);
}

function create_map(file_lines, i) {
  let d = {};
  let blockpin_regex = /<blockpin/;
  let signalname_regex = /signalname="([\w|(|)|:]+)"/;
  let name_regex = /name="([\w|(|)|:]+)" \/>/;
  let bus_regex = /(\w+)\((\d+):(\d+)\)/;
  let ret = file_lines[i].match(blockpin_regex);
  while (ret !== null) {
    let name_ret = file_lines[i].match(name_regex);
    let signame_ret = file_lines[i].match(signalname_regex);
    let n_bus_ret = name_ret[1].match(bus_regex);
    if (signame_ret === null) {
      if (n_bus_ret === null) {
        d[name_ret[1]] = null;
      } else {
        if (Number(n_bus_ret[3]) < Number(n_bus_ret[2])) {
          for (let j = Number(n_bus_ret[3]); j < Number(n_bus_ret[2])+1; j++) {
            d[`${n_bus_ret[1]}(${j})`] = null;
          }
        } else {
          for (let j = Number(n_bus_ret[2]); j < Number(n_bus_ret[3])+1; j++) {
            d[`${n_bus_ret[1]}(${j})`] = null;
          }
        }
      }
      i++;
      ret = file_lines[i].match(blockpin_regex); 
      continue;
    }
    let sn_bus_ret = signame_ret[1].match(bus_regex);
    if (n_bus_ret === null) {
      d[name_ret[1]] = signame_ret[1];
    } else {
      if (Number(n_bus_ret[3]) < Number(n_bus_ret[2])) {
        for (let j = Number(n_bus_ret[3]); j < Number(n_bus_ret[2])+1; j++) {
          d[`${n_bus_ret[1]}(${j})`] = `${sn_bus_ret[1]}(${j})`;
        }
      } else {
        for (let j = Number(n_bus_ret[2]); j < Number(n_bus_ret[3])+1; j++) {
          d[`${n_bus_ret[1]}(${j})`] = `${sn_bus_ret[1]}(${j})`;
        }
      }
    }
    i++;
    ret = file_lines[i].match(blockpin_regex);
  }
  // console.log(d);
  return {'d_map':d, 'i':i};
}

function map_pins(d, new_ops, name) {
  for (let j = 0; j < new_ops.length; j++) {
    for (let i = 0; i < new_ops[j].in.length; i++) {
      if (Object.keys(d).includes(new_ops[j].in[i])) {
        let tmp = new_ops[j].in[i];
        new_ops[j].in[i] = d[tmp];
      } else {
        new_ops[j].in[i] += `_${name}`;
      }
    }
    for (let i = 0; i < new_ops[j].out.length; i++) {
      if (new_ops[j].out[i] === null) {
        continue;
      } else if (Object.keys(d).includes(new_ops[j].out[i])) {
        let tmp = new_ops[j].out[i];
        new_ops[j].out[i] = d[tmp];
      } else {
        new_ops[j].out[i] += `_${name}`;
      }
    }
    let new_state = [];
    for (let i = 0; i < new_ops[j].state.length; i++) {
      if (new_ops[j].state[i] === null) {
        new_state.push(null);
        continue;
      }
      let opname = Object.keys(new_ops[j].state[i])[0];
      if (Object.keys(d).includes(opname)) {
        // console.log(`${opname}`);
        // console.log(JSON.parse(JSON.stringify(d)));
        // console.log(JSON.parse(JSON.stringify(Object.keys(d))));
        // console.log(JSON.parse(JSON.stringify(Object.keys(d).includes(opname))));
        // console.log(d[opname]);
        if (d[opname] !== null) {
          let tmp = d[opname];
          new_state.push({[tmp]:0});
        } else {
          new_state.push(null);
        }
        
      } else {
        let tmp = opname+'_'+name;
        new_state.push({[tmp]:0});
      }
    }
    new_ops[j].state = new_state;
    // console.log("New Ops:");
    // console.log(new_ops);
  }
}

function clock_pulse(prev) {
  CLK ^= 1;
  const out_vals = execute(prev.state.SW, prev.state.BTN); //remember to add the clock later
  let LED_new = prev.state.LED, AN_new = prev.state.AN, SEG_new = prev.state.SEG;
  for (let [key, value] of Object.entries(out_vals)) {
    // console.log(g_ucf[key]);
    if (g_ucf[key] === "U16") LED_new[0] = value;
    else if (g_ucf[key] === "V16") LED_new[1] = value;
    else if (g_ucf[key] === "U15") LED_new[2] = value;
    else if (g_ucf[key] === "V15") LED_new[3] = value;
    else if (g_ucf[key] === "M11") LED_new[4] = value;
    else if (g_ucf[key] === "N11") LED_new[5] = value;
    else if (g_ucf[key] === "R11") LED_new[6] = value;
    else if (g_ucf[key] === "T11") LED_new[7] = value;
    else if (g_ucf[key] === "N16") {
      AN_new[0] = value;
      // if (!value) {
      //   AN_new[0] = value;
      //   if (AN0_id !== -1) {
      //       clearTimeout(AN0_id);
      //     }
      //   AN0_id = setTimeout(AN0_timer, 80, prev);
      // }
    } else if (g_ucf[key] === "N15") {
      AN_new[1] = value;
      // if (!value) {
      //   AN_new[1] = value;
      //   if (AN1_id !== -1) {
      //       clearTimeout(AN1_id);
      //     }
      //   AN1_id = setTimeout(AN1_timer, 80, prev);
      // }
    } else if (g_ucf[key] === "P18") {
      AN_new[2] = value;
      // if (!value) {
      //   AN_new[2] = value;
      //   if (AN2_id !== -1) {
      //       clearTimeout(AN2_id);
      //     }
      //   AN2_id = setTimeout(AN2_timer, 80, prev);
      // }
    } else if (g_ucf[key] === "P17") {
      AN_new[3] = value;
      // if (!value) {
      //   AN_new[3] = value;
      //   if (AN3_id !== -1) {
      //       clearTimeout(AN3_id);
      //     }
      //   AN3_id = setTimeout(AN3_timer, 80, prev);
      // }
    } else if (g_ucf[key] === "T17") value ? SEG_new &= ~0x80 : SEG_new |= 0x80;
    else if (g_ucf[key] === "T18") value ? SEG_new &= ~0x40 : SEG_new |= 0x40;
    else if (g_ucf[key] === "U17") value ? SEG_new &= ~0x20 : SEG_new |= 0x20;
    else if (g_ucf[key] === "U18") value ? SEG_new &= ~0x10 : SEG_new |= 0x10;
    else if (g_ucf[key] === "M14") value ? SEG_new &= ~0x08 : SEG_new |= 0x08;
    else if (g_ucf[key] === "N14") value ? SEG_new &= ~0x04 : SEG_new |= 0x04;
    else if (g_ucf[key] === "L14") value ? SEG_new &= ~0x02 : SEG_new |= 0x02;
    else if (g_ucf[key] === "M13") value ? SEG_new &= ~0x01 : SEG_new |= 0x01;
  }
  // console.log(LED_new);
  prev.setState({
    LED: LED_new,
    AN: AN_new,
    SEG: SEG_new
  });
}

// function AN0_timer(prev) {
//   if (AN_state[0]) {
//     let AN_new = prev.state.AN;
//     AN_new[0] = 1;
//     prev.setState({
//       AN: AN_new
//     })
//   }
//   AN0_id = -1
// }

// function AN1_timer(prev) {
//   if (AN_state[1]) {
//     let AN_new = prev.state.AN;
//     AN_new[1] = 1;
//     prev.setState({
//       AN: AN_new
//     })
//   }
//   AN1_id = -1
// }

// function AN2_timer(prev) {
//   if (AN_state[2]) {
//     let AN_new = prev.state.AN;
//     AN_new[2] = 1;
//     prev.setState({
//       AN: AN_new
//     })
//   }
//   AN2_id = -1
// }

// function AN3_timer(prev) {
//   if (AN_state[3]) {
//     let AN_new = prev.state.AN;
//     AN_new[3] = 1;
//     prev.setState({
//       AN: AN_new
//     })
//   }
//   AN3_id = -1
// }

function read_ucf(inputs, outputs, ops, Files, prev) {
  var reader = new FileReader();
  reader.onload = function(event) {
      var contents = event.target.result;
      let lines = contents.split("\n");
      let ucf_regex = /NET\s*"(\w+)"\s+LOC[\s|=]+"\s*(\w+)\s*"/;
      let ucf = {};
      for (let i = 0; i < lines.length; i++) {
        let ret = lines[i].match(ucf_regex);
        if (ret !== null) {
          ucf[`${ret[1]}`] = ret[2];
        }
      }
      // console.log(ucf);
      const valid_pin = ['C4', 'D9', 'A8', 'C9', 'B8', 'T10', 'T9', 'V9', 'M8', 'N8', 'U8', 'V8', 'T5', 'U16',
      'V16', 'U15', 'V15', 'M11', 'N11', 'R11', 'T11', 'P17', 'P18', 'N15', 'N16', 'T17', 'T18', 'U17', 'U18',
      'M14', 'N14', 'L14', 'M13', 'V10'];
      for (let i = 0; i < inputs.length; i++) {
        if (!Object.keys(ucf).includes(inputs[i])) {
          throw_error(`Input ${inputs[i]} from your schematic does not appear in your ucf file.`);
        }
      }
      for (let i = 0; i < outputs.length; i++) {
        if (!Object.keys(ucf).includes(outputs[i])) {
          throw_error(`Output ${outputs[i]} from your schematic does not appear in your ucf file.`);
        }
      }
      const pins = Object.values(ucf);
      for (let i = 0; i < pins.length; i++) {
        if (!valid_pin.includes(pins[i])) {
          throw_error(`${pins[i]} is not a valid LOC value in your ucf file`);
        }
      }
      g_inputs = inputs;
      g_outputs = outputs;
      g_ops = ops;
      g_ucf = ucf;
      // console.log("Successfully finished interpreting!!");
      const message = document.getElementById("log");
      message.innerHTML = "> Successfully finished interpreting!!";
      const fileInput = document.getElementById("fileUpload");
      try {
        fileInput.value = null;
      } catch(ex) { }
      if (fileInput.value) {
        fileInput.parentNode.replaceChild(fileInput.cloneNode(true), fileInput);
      }
      const out_vals = execute(prev.state.SW, prev.state.BTN); //remember to add the clock later
      let LED_new = prev.state.LED, AN_new = prev.state.AN, SEG_new = prev.state.SEG;
      for (let [key, value] of Object.entries(out_vals)) {
        // console.log(g_ucf[key]);
        if (g_ucf[key] === "U16") LED_new[0] = value;
        else if (g_ucf[key] === "V16") LED_new[1] = value;
        else if (g_ucf[key] === "U15") LED_new[2] = value;
        else if (g_ucf[key] === "V15") LED_new[3] = value;
        else if (g_ucf[key] === "M11") LED_new[4] = value;
        else if (g_ucf[key] === "N11") LED_new[5] = value;
        else if (g_ucf[key] === "R11") LED_new[6] = value;
        else if (g_ucf[key] === "T11") LED_new[7] = value;
        else if (g_ucf[key] === "N16") {
          AN_new[0] = value;
          // if (!value) {
          //   AN_new[0] = value;
          //   if (AN0_id !== -1) {
          //       clearTimeout(AN0_id);
          //     }
          //   AN0_id = setTimeout(AN0_timer, 4000, prev);
          // }
        } else if (g_ucf[key] === "N15") {
          AN_new[1] = value;
          // if (!value) {
          //   AN_new[1] = value;
          //   if (AN1_id !== -1) {
          //       clearTimeout(AN1_id);
          //     }
          //   AN1_id = setTimeout(AN1_timer, 4000, prev);
          // }
        } else if (g_ucf[key] === "P18") {
          AN_new[2] = value;
          // if (!value) {
          //   AN_new[2] = value;
          //   if (AN2_id !== -1) {
          //       clearTimeout(AN2_id);
          //     }
          //   AN2_id = setTimeout(AN2_timer, 4000, prev);
          // }
        } else if (g_ucf[key] === "P17") {
          AN_new[3] = value;
          // if (!value) {
          //   AN_new[3] = value;
          //   if (AN3_id !== -1) {
          //       clearTimeout(AN3_id);
          //     }
          //   AN3_id = setTimeout(AN3_timer, 4000, prev);
          // }
        } else if (g_ucf[key] === "T17") value ? SEG_new &= ~0x80 : SEG_new |= 0x80;
        else if (g_ucf[key] === "T18") value ? SEG_new &= ~0x40 : SEG_new |= 0x40;
        else if (g_ucf[key] === "U17") value ? SEG_new &= ~0x20 : SEG_new |= 0x20;
        else if (g_ucf[key] === "U18") value ? SEG_new &= ~0x10 : SEG_new |= 0x10;
        else if (g_ucf[key] === "M14") value ? SEG_new &= ~0x08 : SEG_new |= 0x08;
        else if (g_ucf[key] === "N14") value ? SEG_new &= ~0x04 : SEG_new |= 0x04;
        else if (g_ucf[key] === "L14") value ? SEG_new &= ~0x02 : SEG_new |= 0x02;
        else if (g_ucf[key] === "M13") value ? SEG_new &= ~0x01 : SEG_new |= 0x01;
      }
      // console.log(LED_new);
      prev.setState({
        LED: LED_new,
        AN: AN_new,
        SEG: SEG_new
      });
      if (g_inputs.some(elem => {return g_ucf[elem] === "V10"})) setInterval(clock_pulse, 4, prev);
  };
  reader.onerror = function(event) {
      console.error("File could not be read! Code " + event.target.error.code);
  };
  let curr_file;
  let found = false;
  let search_str = ucf_filename;
  // console.log(search_str);
  for (let i = 0; i < Files.length; i++) {
    curr_file = Files[i];
    if (curr_file.name === search_str) {
      reader.readAsText(curr_file);
      found = true;
      break;
    }
  }
  if (!found) {
    throw_error(`${search_str} not found`);
  }
}

function interpret_files(file_str, Files, prev) {
  let curr_file;
  let found = -1;
  for (let i = 0; i < all_schematics.length; i++) {
    curr_file = all_schematics[i];
    if (curr_file.name === file_str) {
      found = i;
      break;
    }
  }
  if (found === -1) {
    throw_error(`${file_str} not found. If you created this module, please ensure you have uploaded it. If this is a XILINX module, please contact jmeggs@outlook.com.au`);
  }
  console.log(`looking for ${file_str}`);
  console.log(`found ${curr_file.name}`);
  const contents = all_schematics[found].contents;
  let lines = contents.split("\n");
  // console.log(contents);
  let inputs = [];
  let outputs = [];
  let inout_regex = /<port polarity="(\w+)put" name="(\w+)"/;
  // Find inputs and outputs
  for (let i = 0; i < lines.length; i++) {
    let ret = lines[i].match(inout_regex);
    // console.log(ret);
    if (ret !== null)
      (ret[1] === "In") ? inputs.push(ret[2]) : outputs.push(ret[2]);
  }
  // console.log(inputs);
  // console.log(outputs);
  // Identify blocks
  let blocks = [];
  let block_regex = /<block symbolname="(\w+)"/;
  let i = 0;
  window.read_modules = read_modules;
  window.modules = modules;
  while (i < lines.length) {
    let ret = lines[i].match(block_regex);
    if (ret !== null) {
      // console.log(`line is ${lines[i]}`);
      // console.log("ret is:");
      // console.log(JSON.parse(JSON.stringify(ret)));
      blocks.push({'name':ret[1], 'in':[], 'out':[], 'state':[]});
      try {
        //i = eval(ret[1])(null, lines, i, blocks[blocks.length-1]);
        // const ret_tmp = String(ret[1]);
        // console.log(`ret_temp is ${ret_tmp}`);
        i = window.read_modules[ret[1]](lines, i, blocks[blocks.length-1]);
        // console.log(window);
        // console.log(`Successfully read ${ret[1]}!\n`);
      } catch (err) {
        if (err.message.includes("Cannot read property '1' of null")) {
          throw_error(`Floating input detected on module ${ret[1]}.\nPlease fix and try again.`);
        } else if (err.message.includes("window.read_modules") && err.message.includes("is not a function")) {
          let new_data = interpret_files(ret[1]+'.sch', Files, prev);
          // while (new_data === undefined) {};
          let new_blocks = new_data.blocks;
          // console.log("New_blocks:");
          // console.log(JSON.parse(JSON.stringify(new_blocks)));
          if (new_blocks === []) {
            throw_error(`Module ${ret[1]} is empty.`);
          }
          blocks.pop();
          i++;
          let map_ret = create_map(lines, i);
          // console.log("Map obj:");
          // console.log(JSON.parse(JSON.stringify(map_ret)));
          i = map_ret.i;
          map_pins(map_ret.d_map, new_blocks, ret[1]);
          // console.log("Changed new_blocks:");
          // console.log(JSON.parse(JSON.stringify(new_blocks)));
          // console.log(JSON.parse(JSON.stringify(blocks)));
          blocks = blocks.concat(new_blocks);
          // console.log(JSON.parse(JSON.stringify(blocks)));
          continue;
          //throw_error(`Floating input detected on module ${ret[1]}.\nPlease fix and try again.`);
        } else {
          throw_error(`An unknown error occurred whilst attempting to read module ${ret[1]}.\nPlease contact jmeggs@outlook.com.au so this can be fixed.`);
        }
      }
      // console.log("No error, blocks are:");
      // console.log(JSON.parse(JSON.stringify(blocks)));
    }
    i++;
  }
  // console.log(blocks);
  // Return if we aren't the top-level
  // console.log(`Top Level is ${top_level}\n`);
  // console.log(`curr_file.name is ${curr_file.name}\n`);
  if (curr_file.name !== top_level) {
    // console.log("Returning since not top level\n");
    return {'blocks':blocks};
  }
  // Order blocks based upon dependency. Strategy is to have a list of available nets. 
  // Find any blocks that have inputs that are in this list, and add them to ops list,
  // and append outputs to available nets
  let ops = [];
  let avail_nets = inputs.concat([]);
  let append;
  let len = blocks.length;
  for (let n = 0; n < len; n++) {
    // console.log("Iterating, vals are:");
    // console.log(blocks);
    // console.log(avail_nets);
    // console.log(ops);
    for (let j = 0; j < blocks.length; j++) {
      append = true;
      // console.log(blocks[j]);
      for (let k = 0; k < blocks[j].in.length; k++) {
        if (!avail_nets.includes(blocks[j].in[k])) {
          append = false;
          break;
        }
      }
      if (append) {
        ops.push(blocks[j]);
        avail_nets = avail_nets.concat(blocks[j].out);
        // console.log("Adding states");
        for (let k = 0; k < blocks[j].state.length; k++) {
          if (blocks[j].state[k] !== null) {
            // console.log(JSON.parse(JSON.stringify(blocks[j].state[k])));
            avail_nets.push(Object.keys(blocks[j].state[k])[0]);
            // console.log(JSON.parse(JSON.stringify(avail_nets)));            
          }
        }
        blocks.splice(j,1);
      }
    }
    if (blocks.length === 0) {
      break;
    }
  }
  if (blocks.length !== 0) {
    throw_error("Unable to determine a sequence of inputs. Please check your schematic.");
  }
  // console.log(ops);
  // Scan in UCF file
  read_ucf(inputs, outputs, ops, Files, prev);
}

function execute(sw, btn) { //should probs make this async at some point
  let out_vals = {};
  const all_states = {"T10":sw[0], "T9":sw[1], "V9":sw[2], "M8":sw[3], "N8":sw[4],
            "U8":sw[5], "V8":sw[6], "T5":sw[7], "A8":btn[0], "C4":btn[1], "B8": btn[2],
            "D9":btn[3], "C9":btn[4], "V10":CLK};
  // console.log(`${CLK}`);
  let states = {};
  let len = g_inputs.length;
  for (let i = 0; i < len; i++) {
    states[g_inputs[i]] = all_states[g_ucf[g_inputs[i]]];
  }
  // console.log(JSON.parse(JSON.stringify(states)));
  len = g_ops.length;
  let arg1, len2, nm, dic, ret, n;
  const winst = window.modules;
  for (let i = 0; i < len; i++) {
    // console.log(`Doing op ${g_ops[i].name}`);
    arg1 = []
    len2 = g_ops[i].in.length;
    for (let j = 0; j < len2; j++) {
      nm = g_ops[i].in[j];
      // console.log(nm);
      // console.log(g_ucf);
      // console.log(g_ucf[nm]);
      // if (g_ops[i].name === "cd4ce") console.log(`${nm} ${g_inputs.includes(nm) ? states[nm] : out_vals[nm]}`);
      // if (g_ops[i].name === "cd4ce") console.log(JSON.parse(JSON.stringify(out_vals["CLOCK"])));
      arg1.push(g_inputs.includes(nm) ? states[nm] : out_vals[nm]);
    }
    len2 = g_ops[i].state.length;
    for (let j = 0; j < len2; j++) {
      dic = g_ops[i].state[j];
      if (dic !== null) {
        arg1.push(Object.values(dic)[0]);
      } else {
        arg1.push(0);
      }
    }
    // console.log("arg1 is:");
    // console.log(arg1);
    // if (g_ops[i].name === "cd4ce") console.log(JSON.parse(JSON.stringify(arg1)));
    ret = winst[g_ops[i].name](arg1);
    // console.log("ret is:");
    // console.log(ret);
    n = ret.length-g_ops[i].state.length;
    for (let j = 0; j < n; j++) {
      if (ret[j] === null) {
        continue;
      }
      out_vals[g_ops[i].out[j]] = ret[j];
    }
    len2 = ret.length;
    for (let j = n; j < len2; j++) {
      if (g_ops[i].state[j-n] !== null) {
        nm = Object.keys(g_ops[i].state[j-n])[0]
        out_vals[nm] = ret[j];
        g_ops[i].state[j-n][nm] = ret[j];
      }
    }
    // console.log("outvals is:");
    // console.log(out_vals);
  }
  Object.keys(out_vals).forEach(elem => {if (!g_outputs.includes(elem)) delete out_vals[elem]});
  // console.log(out_vals);
  return out_vals;
}
 
class DrongoSim extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      SW: [0,0,0,0,0,0,0,0],
      BTN: [0,0,0,0,0],
      LED: [0,0,0,0,0,0,0,0],
      AN: [1,1,1,1],
      SEG: 0
    };
 
    this.organise_files = this.organise_files.bind(this);
    this.toggleSwitch = this.toggleSwitch.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
  }

  toggleSwitch(num, e) {
    let SW_new = this.state.SW.map((elem, i) => {return (i === num) ? elem^1 : elem});
    this.setState({
      SW: SW_new
    });
    const out_vals = execute(SW_new, this.state.BTN); //remember to add the clock later
    let LED_new = this.state.LED, AN_new = this.state.AN, SEG_new = this.state.SEG;
    for (let [key, value] of Object.entries(out_vals)) {
      // console.log(g_ucf[key]);
      if (g_ucf[key] === "U16") LED_new[0] = value;
      else if (g_ucf[key] === "V16") LED_new[1] = value;
      else if (g_ucf[key] === "U15") LED_new[2] = value;
      else if (g_ucf[key] === "V15") LED_new[3] = value;
      else if (g_ucf[key] === "M11") LED_new[4] = value;
      else if (g_ucf[key] === "N11") LED_new[5] = value;
      else if (g_ucf[key] === "R11") LED_new[6] = value;
      else if (g_ucf[key] === "T11") LED_new[7] = value;
      else if (g_ucf[key] === "N16") {
        AN_new[0] = value;
        // if (!value) {
        //   AN_new[0] = value;
        //   if (AN0_id !== -1) {
        //       clearTimeout(AN0_id);
        //     }
        //   AN0_id = setTimeout(AN0_timer, 80, this);
        // }
      } else if (g_ucf[key] === "N15") {
        AN_new[1] = value;
        // if (!value) {
        //   AN_new[1] = value;
        //   if (AN1_id !== -1) {
        //       clearTimeout(AN1_id);
        //     }
        //   AN1_id = setTimeout(AN1_timer, 80, this);
        // }
      } else if (g_ucf[key] === "P18") {
        AN_new[2] = value;
        // if (!value) {
        //   AN_new[2] = value;
        //   if (AN2_id !== -1) {
        //       clearTimeout(AN2_id);
        //     }
        //   AN2_id = setTimeout(AN2_timer, 80, this);
        // }
      } else if (g_ucf[key] === "P17") {
        AN_new[3] = value;
        // if (!value) {
        //   AN_new[3] = value;
        //   if (AN3_id !== -1) {
        //       clearTimeout(AN3_id);
        //     }
        //   AN3_id = setTimeout(AN3_timer, 80, this);
        // }
      } else if (g_ucf[key] === "T17") value ? SEG_new &= ~0x80 : SEG_new |= 0x80;
      else if (g_ucf[key] === "T18") value ? SEG_new &= ~0x40 : SEG_new |= 0x40;
      else if (g_ucf[key] === "U17") value ? SEG_new &= ~0x20 : SEG_new |= 0x20;
      else if (g_ucf[key] === "U18") value ? SEG_new &= ~0x10 : SEG_new |= 0x10;
      else if (g_ucf[key] === "M14") value ? SEG_new &= ~0x08 : SEG_new |= 0x08;
      else if (g_ucf[key] === "N14") value ? SEG_new &= ~0x04 : SEG_new |= 0x04;
      else if (g_ucf[key] === "L14") value ? SEG_new &= ~0x02 : SEG_new |= 0x02;
      else if (g_ucf[key] === "M13") value ? SEG_new &= ~0x01 : SEG_new |= 0x01;
    }
    // console.log(LED_new);
    this.setState({
      LED: LED_new,
      AN: AN_new,
      SEG: SEG_new
    });
  }

  toggleButton(num, e) {
    // console.log("In button!");
    let BTN_new = this.state.BTN.map((elem, i) => {return (i === num) ? elem^1 : elem});
    this.setState({
      BTN: BTN_new
    });
    const out_vals = execute(this.state.SW, BTN_new); //remember to add the clock later
    let LED_new = this.state.LED, AN_new = this.state.AN, SEG_new = this.state.SEG;
    for (let [key, value] of Object.entries(out_vals)) {
      // console.log(g_ucf[key]);
      if (g_ucf[key] === "U16") LED_new[0] = value;
      else if (g_ucf[key] === "V16") LED_new[1] = value;
      else if (g_ucf[key] === "U15") LED_new[2] = value;
      else if (g_ucf[key] === "V15") LED_new[3] = value;
      else if (g_ucf[key] === "M11") LED_new[4] = value;
      else if (g_ucf[key] === "N11") LED_new[5] = value;
      else if (g_ucf[key] === "R11") LED_new[6] = value;
      else if (g_ucf[key] === "T11") LED_new[7] = value;
      else if (g_ucf[key] === "N16") AN_new[0] = value;
      else if (g_ucf[key] === "N15") AN_new[1] = value;
      else if (g_ucf[key] === "P18") AN_new[2] = value;
      else if (g_ucf[key] === "P17") AN_new[3] = value;
      else if (g_ucf[key] === "T17") value ? SEG_new &= ~0x80 : SEG_new |= 0x80;
      else if (g_ucf[key] === "T18") value ? SEG_new &= ~0x40 : SEG_new |= 0x40;
      else if (g_ucf[key] === "U17") value ? SEG_new &= ~0x20 : SEG_new |= 0x20;
      else if (g_ucf[key] === "U18") value ? SEG_new &= ~0x10 : SEG_new |= 0x10;
      else if (g_ucf[key] === "M14") value ? SEG_new &= ~0x08 : SEG_new |= 0x08;
      else if (g_ucf[key] === "N14") value ? SEG_new &= ~0x04 : SEG_new |= 0x04;
      else if (g_ucf[key] === "L14") value ? SEG_new &= ~0x02 : SEG_new |= 0x02;
      else if (g_ucf[key] === "M13") value ? SEG_new &= ~0x01 : SEG_new |= 0x01;
    }
    // console.log(JSON.parse(JSON.stringify(AN_new)));
    // console.log(LED_new);
    this.setState({
      LED: LED_new,
      AN: AN_new,
      SEG: SEG_new
    });
  }

  componentDidMount() {
    document.getElementById("fileUpload").addEventListener("change", this.organise_files);
  }
 
  componentWillUnmount() {
    document.getElementById("fileUpload").removeEventListener("change", this.organise_files);
  }

  organise_files() {
    // console.log("Loading");
    g_inputs = [];
    g_outputs = [];
    g_ops = [];
    g_ucf = {};
    all_schematics = [];
    this.setState({
      SW: [0,0,0,0,0,0,0,0],
      BTN: [0,0,0,0,0],
      LED: [0,0,0,0,0,0,0,0],
      AN: [0,0,0,0],
      SEG: 0
    });
    if (clk_id !== -1) {
      clearInterval(clk_id);
    }
    clk_id = -1;
    CLK = 0;
    const message = document.getElementById("log");
    message.innerHTML = "";
    let Files = document.getElementById('fileUpload').files;
    let nFiles = Files.length;
    let curr_file;
    let found = false;
    this.setState({
      SW: [0,0,0,0,0,0,0,0],
      BTN: [0,0,0,0,0],
      LED: [0,0,0,0,0,0,0,0],
      AN: [1,1,1,1],
      SEG: 0
    });
    for (let i = 0; i < nFiles; i++) {
      curr_file = Files[i];
      if (curr_file.name.endsWith('.xise')) {
        found=true;
        find_top_level(curr_file, Files, this);
        break;
      }
    }
    if (!found) {
      throw_error("Could not find .xise file.");
    }
  }

  uploadFiles() {
    const fileElem = document.getElementById("fileUpload");
    fileElem.click();
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Jonah Meggs | DrongoSim</title>
          <meta name="description" content="DrongoSim is an FPGA simulator designed for the UNSW ELEC2141 labs. 
        It interprets your project file (.xise), schematics (.sch) and constraints file (.ucf), 
        and simulates what the actual board would be doing." />
        </Helmet>
        <div className="container">
          <div className="left_col">
            <input type="file" id="fileUpload" name="fileUpload" accept=".sch, .ucf, .xise" style={{display:"none"}} multiple/>
            <button id="fileSelect" onClick={this.uploadFiles}>Select some files</button>
          </div>
          <div className="middle_col">
            <FPGA toggleSwitch={this.toggleSwitch} toggleButton={this.toggleButton} SW={this.state.SW} BTN={this.state.BTN} LED={this.state.LED} AN={this.state.AN} SEG={this.state.SEG}/>
          </div>
          <div className="right_col">
            <p className="DrongoTerm">DrongoTerm</p>
            <p id="log">{'>'}</p>
          </div>
        </div>
        <h3>Read Me!!</h3>
        <h4>What is DrongoSim?</h4>
        <p>DrongoSim is an FPGA simulator designed for the UNSW ELEC2141 labs. 
        It interprets your project file (.xise), schematics (.sch) and constraints file (.ucf), 
        and simulates what the actual board would be doing.
        The board simulated is a Digilent Nexys-3 featuring a Spartan-6 FPGA. It is now obsolete given that the labs now 
        focus more on Verilog than on schematics. (this is a good thing!)</p>
        <h4>Sounds cool, how do I use it?</h4>
        <p>Once you have completed your design in a XILINX ISE project (set up for the aforementioned FPGA), 
        ensure that you can successfully "Synthesize" and "Implement" your design in the ISE without any errors or serious warnings. 
        To give your files to DrongoSim, click "Select some files" and select your .xise, .sch and .ucf files (all at once - use the ctrl key). 
        DrongoSim will load your files and notify you of any errors. If successfully loaded, nice work! The virtual FPGA is running!</p>
        <h4>It's not working :-(</h4>
        <ul>
          <li>Ensure your .ucf file has the same prefix as your top-level schematic (e.g. drongo.sch, drongo.ucf).</li>
          <li>Ensure no serious warnings occur when synthesizing in the ISE. Warnings you can ignore include those relating to subscription lapses, 
          and for lab7 warnings regarding the output of the prescaler and truncation.</li>
        </ul>
        <p>If all else fails, there is the possibility it's a bug. Flick me an email via the contact page of this site, attaching your .xise, .sch and .ucf files.</p>
        <h4>Anything else I should know?</h4>
        <p>DrongoSim is a tad slow. Due to the fact that it is implemented in an interpreted language running in your web browser, 
        the maximum clock speed (for lab7) is 125Hz (compare that to the 100MHz clock on the real board!). The major implication of this is 
        that DrongoSim isn't fast enough to drive the 4-digit 7-segment display without a little tweaking. In appendix A of your lab manual, you'll find that 
        each digit needs to be refreshed at least every 16ms (i.e. a value displayed on a digit will persist or "hang around" for 16ms after it is turned off). 
        If you do the maths, that requires a 250Hz clock in order to refresh each of the 4 digits before they fade. DrongoSim is a little sneaky and doubles the time a digit persists for, essentially halving the frequency needed to drive it.</p>
      </div>
    );
  }
}
 
export default DrongoSim;
