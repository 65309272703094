import React, { Component } from "react";
import "./Seg.css";
import img00000000 from "./digit/00000000.png";
import img00000001 from "./digit/00000001.png";
import img00000010 from "./digit/00000010.png";
import img00000011 from "./digit/00000011.png";
import img00000100 from "./digit/00000100.png";
import img00000101 from "./digit/00000101.png";
import img00000110 from "./digit/00000110.png";
import img00000111 from "./digit/00000111.png";
import img00001000 from "./digit/00001000.png";
import img00001001 from "./digit/00001001.png";
import img00001010 from "./digit/00001010.png";
import img00001011 from "./digit/00001011.png";
import img00001100 from "./digit/00001100.png";
import img00001101 from "./digit/00001101.png";
import img00001110 from "./digit/00001110.png";
import img00001111 from "./digit/00001111.png";
import img00010000 from "./digit/00010000.png";
import img00010001 from "./digit/00010001.png";
import img00010010 from "./digit/00010010.png";
import img00010011 from "./digit/00010011.png";
import img00010100 from "./digit/00010100.png";
import img00010101 from "./digit/00010101.png";
import img00010110 from "./digit/00010110.png";
import img00010111 from "./digit/00010111.png";
import img00011000 from "./digit/00011000.png";
import img00011001 from "./digit/00011001.png";
import img00011010 from "./digit/00011010.png";
import img00011011 from "./digit/00011011.png";
import img00011100 from "./digit/00011100.png";
import img00011101 from "./digit/00011101.png";
import img00011110 from "./digit/00011110.png";
import img00011111 from "./digit/00011111.png";
import img00100000 from "./digit/00100000.png";
import img00100001 from "./digit/00100001.png";
import img00100010 from "./digit/00100010.png";
import img00100011 from "./digit/00100011.png";
import img00100100 from "./digit/00100100.png";
import img00100101 from "./digit/00100101.png";
import img00100110 from "./digit/00100110.png";
import img00100111 from "./digit/00100111.png";
import img00101000 from "./digit/00101000.png";
import img00101001 from "./digit/00101001.png";
import img00101010 from "./digit/00101010.png";
import img00101011 from "./digit/00101011.png";
import img00101100 from "./digit/00101100.png";
import img00101101 from "./digit/00101101.png";
import img00101110 from "./digit/00101110.png";
import img00101111 from "./digit/00101111.png";
import img00110000 from "./digit/00110000.png";
import img00110001 from "./digit/00110001.png";
import img00110010 from "./digit/00110010.png";
import img00110011 from "./digit/00110011.png";
import img00110100 from "./digit/00110100.png";
import img00110101 from "./digit/00110101.png";
import img00110110 from "./digit/00110110.png";
import img00110111 from "./digit/00110111.png";
import img00111000 from "./digit/00111000.png";
import img00111001 from "./digit/00111001.png";
import img00111010 from "./digit/00111010.png";
import img00111011 from "./digit/00111011.png";
import img00111100 from "./digit/00111100.png";
import img00111101 from "./digit/00111101.png";
import img00111110 from "./digit/00111110.png";
import img00111111 from "./digit/00111111.png";
import img01000000 from "./digit/01000000.png";
import img01000001 from "./digit/01000001.png";
import img01000010 from "./digit/01000010.png";
import img01000011 from "./digit/01000011.png";
import img01000100 from "./digit/01000100.png";
import img01000101 from "./digit/01000101.png";
import img01000110 from "./digit/01000110.png";
import img01000111 from "./digit/01000111.png";
import img01001000 from "./digit/01001000.png";
import img01001001 from "./digit/01001001.png";
import img01001010 from "./digit/01001010.png";
import img01001011 from "./digit/01001011.png";
import img01001100 from "./digit/01001100.png";
import img01001101 from "./digit/01001101.png";
import img01001110 from "./digit/01001110.png";
import img01001111 from "./digit/01001111.png";
import img01010000 from "./digit/01010000.png";
import img01010001 from "./digit/01010001.png";
import img01010010 from "./digit/01010010.png";
import img01010011 from "./digit/01010011.png";
import img01010100 from "./digit/01010100.png";
import img01010101 from "./digit/01010101.png";
import img01010110 from "./digit/01010110.png";
import img01010111 from "./digit/01010111.png";
import img01011000 from "./digit/01011000.png";
import img01011001 from "./digit/01011001.png";
import img01011010 from "./digit/01011010.png";
import img01011011 from "./digit/01011011.png";
import img01011100 from "./digit/01011100.png";
import img01011101 from "./digit/01011101.png";
import img01011110 from "./digit/01011110.png";
import img01011111 from "./digit/01011111.png";
import img01100000 from "./digit/01100000.png";
import img01100001 from "./digit/01100001.png";
import img01100010 from "./digit/01100010.png";
import img01100011 from "./digit/01100011.png";
import img01100100 from "./digit/01100100.png";
import img01100101 from "./digit/01100101.png";
import img01100110 from "./digit/01100110.png";
import img01100111 from "./digit/01100111.png";
import img01101000 from "./digit/01101000.png";
import img01101001 from "./digit/01101001.png";
import img01101010 from "./digit/01101010.png";
import img01101011 from "./digit/01101011.png";
import img01101100 from "./digit/01101100.png";
import img01101101 from "./digit/01101101.png";
import img01101110 from "./digit/01101110.png";
import img01101111 from "./digit/01101111.png";
import img01110000 from "./digit/01110000.png";
import img01110001 from "./digit/01110001.png";
import img01110010 from "./digit/01110010.png";
import img01110011 from "./digit/01110011.png";
import img01110100 from "./digit/01110100.png";
import img01110101 from "./digit/01110101.png";
import img01110110 from "./digit/01110110.png";
import img01110111 from "./digit/01110111.png";
import img01111000 from "./digit/01111000.png";
import img01111001 from "./digit/01111001.png";
import img01111010 from "./digit/01111010.png";
import img01111011 from "./digit/01111011.png";
import img01111100 from "./digit/01111100.png";
import img01111101 from "./digit/01111101.png";
import img01111110 from "./digit/01111110.png";
import img01111111 from "./digit/01111111.png";
import img10000000 from "./digit/10000000.png";
import img10000001 from "./digit/10000001.png";
import img10000010 from "./digit/10000010.png";
import img10000011 from "./digit/10000011.png";
import img10000100 from "./digit/10000100.png";
import img10000101 from "./digit/10000101.png";
import img10000110 from "./digit/10000110.png";
import img10000111 from "./digit/10000111.png";
import img10001000 from "./digit/10001000.png";
import img10001001 from "./digit/10001001.png";
import img10001010 from "./digit/10001010.png";
import img10001011 from "./digit/10001011.png";
import img10001100 from "./digit/10001100.png";
import img10001101 from "./digit/10001101.png";
import img10001110 from "./digit/10001110.png";
import img10001111 from "./digit/10001111.png";
import img10010000 from "./digit/10010000.png";
import img10010001 from "./digit/10010001.png";
import img10010010 from "./digit/10010010.png";
import img10010011 from "./digit/10010011.png";
import img10010100 from "./digit/10010100.png";
import img10010101 from "./digit/10010101.png";
import img10010110 from "./digit/10010110.png";
import img10010111 from "./digit/10010111.png";
import img10011000 from "./digit/10011000.png";
import img10011001 from "./digit/10011001.png";
import img10011010 from "./digit/10011010.png";
import img10011011 from "./digit/10011011.png";
import img10011100 from "./digit/10011100.png";
import img10011101 from "./digit/10011101.png";
import img10011110 from "./digit/10011110.png";
import img10011111 from "./digit/10011111.png";
import img10100000 from "./digit/10100000.png";
import img10100001 from "./digit/10100001.png";
import img10100010 from "./digit/10100010.png";
import img10100011 from "./digit/10100011.png";
import img10100100 from "./digit/10100100.png";
import img10100101 from "./digit/10100101.png";
import img10100110 from "./digit/10100110.png";
import img10100111 from "./digit/10100111.png";
import img10101000 from "./digit/10101000.png";
import img10101001 from "./digit/10101001.png";
import img10101010 from "./digit/10101010.png";
import img10101011 from "./digit/10101011.png";
import img10101100 from "./digit/10101100.png";
import img10101101 from "./digit/10101101.png";
import img10101110 from "./digit/10101110.png";
import img10101111 from "./digit/10101111.png";
import img10110000 from "./digit/10110000.png";
import img10110001 from "./digit/10110001.png";
import img10110010 from "./digit/10110010.png";
import img10110011 from "./digit/10110011.png";
import img10110100 from "./digit/10110100.png";
import img10110101 from "./digit/10110101.png";
import img10110110 from "./digit/10110110.png";
import img10110111 from "./digit/10110111.png";
import img10111000 from "./digit/10111000.png";
import img10111001 from "./digit/10111001.png";
import img10111010 from "./digit/10111010.png";
import img10111011 from "./digit/10111011.png";
import img10111100 from "./digit/10111100.png";
import img10111101 from "./digit/10111101.png";
import img10111110 from "./digit/10111110.png";
import img10111111 from "./digit/10111111.png";
import img11000000 from "./digit/11000000.png";
import img11000001 from "./digit/11000001.png";
import img11000010 from "./digit/11000010.png";
import img11000011 from "./digit/11000011.png";
import img11000100 from "./digit/11000100.png";
import img11000101 from "./digit/11000101.png";
import img11000110 from "./digit/11000110.png";
import img11000111 from "./digit/11000111.png";
import img11001000 from "./digit/11001000.png";
import img11001001 from "./digit/11001001.png";
import img11001010 from "./digit/11001010.png";
import img11001011 from "./digit/11001011.png";
import img11001100 from "./digit/11001100.png";
import img11001101 from "./digit/11001101.png";
import img11001110 from "./digit/11001110.png";
import img11001111 from "./digit/11001111.png";
import img11010000 from "./digit/11010000.png";
import img11010001 from "./digit/11010001.png";
import img11010010 from "./digit/11010010.png";
import img11010011 from "./digit/11010011.png";
import img11010100 from "./digit/11010100.png";
import img11010101 from "./digit/11010101.png";
import img11010110 from "./digit/11010110.png";
import img11010111 from "./digit/11010111.png";
import img11011000 from "./digit/11011000.png";
import img11011001 from "./digit/11011001.png";
import img11011010 from "./digit/11011010.png";
import img11011011 from "./digit/11011011.png";
import img11011100 from "./digit/11011100.png";
import img11011101 from "./digit/11011101.png";
import img11011110 from "./digit/11011110.png";
import img11011111 from "./digit/11011111.png";
import img11100000 from "./digit/11100000.png";
import img11100001 from "./digit/11100001.png";
import img11100010 from "./digit/11100010.png";
import img11100011 from "./digit/11100011.png";
import img11100100 from "./digit/11100100.png";
import img11100101 from "./digit/11100101.png";
import img11100110 from "./digit/11100110.png";
import img11100111 from "./digit/11100111.png";
import img11101000 from "./digit/11101000.png";
import img11101001 from "./digit/11101001.png";
import img11101010 from "./digit/11101010.png";
import img11101011 from "./digit/11101011.png";
import img11101100 from "./digit/11101100.png";
import img11101101 from "./digit/11101101.png";
import img11101110 from "./digit/11101110.png";
import img11101111 from "./digit/11101111.png";
import img11110000 from "./digit/11110000.png";
import img11110001 from "./digit/11110001.png";
import img11110010 from "./digit/11110010.png";
import img11110011 from "./digit/11110011.png";
import img11110100 from "./digit/11110100.png";
import img11110101 from "./digit/11110101.png";
import img11110110 from "./digit/11110110.png";
import img11110111 from "./digit/11110111.png";
import img11111000 from "./digit/11111000.png";
import img11111001 from "./digit/11111001.png";
import img11111010 from "./digit/11111010.png";
import img11111011 from "./digit/11111011.png";
import img11111100 from "./digit/11111100.png";
import img11111101 from "./digit/11111101.png";
import img11111110 from "./digit/11111110.png";
import img11111111 from "./digit/11111111.png";

const imgArr = [img00000000,
img00000001,
img00000010,
img00000011,
img00000100,
img00000101,
img00000110,
img00000111,
img00001000,
img00001001,
img00001010,
img00001011,
img00001100,
img00001101,
img00001110,
img00001111,
img00010000,
img00010001,
img00010010,
img00010011,
img00010100,
img00010101,
img00010110,
img00010111,
img00011000,
img00011001,
img00011010,
img00011011,
img00011100,
img00011101,
img00011110,
img00011111,
img00100000,
img00100001,
img00100010,
img00100011,
img00100100,
img00100101,
img00100110,
img00100111,
img00101000,
img00101001,
img00101010,
img00101011,
img00101100,
img00101101,
img00101110,
img00101111,
img00110000,
img00110001,
img00110010,
img00110011,
img00110100,
img00110101,
img00110110,
img00110111,
img00111000,
img00111001,
img00111010,
img00111011,
img00111100,
img00111101,
img00111110,
img00111111,
img01000000,
img01000001,
img01000010,
img01000011,
img01000100,
img01000101,
img01000110,
img01000111,
img01001000,
img01001001,
img01001010,
img01001011,
img01001100,
img01001101,
img01001110,
img01001111,
img01010000,
img01010001,
img01010010,
img01010011,
img01010100,
img01010101,
img01010110,
img01010111,
img01011000,
img01011001,
img01011010,
img01011011,
img01011100,
img01011101,
img01011110,
img01011111,
img01100000,
img01100001,
img01100010,
img01100011,
img01100100,
img01100101,
img01100110,
img01100111,
img01101000,
img01101001,
img01101010,
img01101011,
img01101100,
img01101101,
img01101110,
img01101111,
img01110000,
img01110001,
img01110010,
img01110011,
img01110100,
img01110101,
img01110110,
img01110111,
img01111000,
img01111001,
img01111010,
img01111011,
img01111100,
img01111101,
img01111110,
img01111111,
img10000000,
img10000001,
img10000010,
img10000011,
img10000100,
img10000101,
img10000110,
img10000111,
img10001000,
img10001001,
img10001010,
img10001011,
img10001100,
img10001101,
img10001110,
img10001111,
img10010000,
img10010001,
img10010010,
img10010011,
img10010100,
img10010101,
img10010110,
img10010111,
img10011000,
img10011001,
img10011010,
img10011011,
img10011100,
img10011101,
img10011110,
img10011111,
img10100000,
img10100001,
img10100010,
img10100011,
img10100100,
img10100101,
img10100110,
img10100111,
img10101000,
img10101001,
img10101010,
img10101011,
img10101100,
img10101101,
img10101110,
img10101111,
img10110000,
img10110001,
img10110010,
img10110011,
img10110100,
img10110101,
img10110110,
img10110111,
img10111000,
img10111001,
img10111010,
img10111011,
img10111100,
img10111101,
img10111110,
img10111111,
img11000000,
img11000001,
img11000010,
img11000011,
img11000100,
img11000101,
img11000110,
img11000111,
img11001000,
img11001001,
img11001010,
img11001011,
img11001100,
img11001101,
img11001110,
img11001111,
img11010000,
img11010001,
img11010010,
img11010011,
img11010100,
img11010101,
img11010110,
img11010111,
img11011000,
img11011001,
img11011010,
img11011011,
img11011100,
img11011101,
img11011110,
img11011111,
img11100000,
img11100001,
img11100010,
img11100011,
img11100100,
img11100101,
img11100110,
img11100111,
img11101000,
img11101001,
img11101010,
img11101011,
img11101100,
img11101101,
img11101110,
img11101111,
img11110000,
img11110001,
img11110010,
img11110011,
img11110100,
img11110101,
img11110110,
img11110111,
img11111000,
img11111001,
img11111010,
img11111011,
img11111100,
img11111101,
img11111110,
img11111111];

// let AN_state;
let AN_id = -1;

function AN_timer(prev) {
  // if (AN_state) {
    prev.forceUpdate();
  // }
  AN_id = -1
}
 
class Seg extends Component {
  shouldComponentUpdate(newProps, newState) {
    // console.log(`Should I? ${this.props.AN} ${this.props.SEG} ${newProps.AN}`);
    if (!this.props.AN && newProps.AN !== this.props.AN) {
      // AN_state = this.props.AN;
      if (AN_id !== -1) {
        clearTimeout(AN_id);
      }
      AN_id = setTimeout(AN_timer, 32, this);
    }
    return !newProps.AN;
  }
  render() {
    // console.log(`Rendering! ${this.props.AN} ${this.props.SEG}`);
    // AN_state = this.props.AN;
    // if (!AN_state) {
    //   if (AN_id !== -1) {
    //     clearTimeout(AN_id);
    //   }
    //   AN_id = setTimeout(AN_timer, 32, this);
    // }
    let image = (this.props.AN) ? imgArr[0] : imgArr[this.props.SEG];
    return (
      <div>
      	<img alt="" className="digit" src={image}/>
      </div>
    );
  }
}
 
export default Seg;