import React, { Component } from "react";
import "./Switch.css";
import switch_on from "./switch_on.png";
import switch_off from "./switch_off.png";
 
class Switch extends Component {
  // constructor(props) {
  //   super(props);
 
  //   // this.state = {
  //   //   on: 0
  //   // };
  //   // this.toggleSwitch = this.toggleSwitch.bind(this);
  // }

  // toggleSwitch(e) {
  //   this.setState({
  //     on: this.state.on ^ 1
  //   });

  // }

  render() {
    let image = switch_off;
    if (this.props.SW) {
      image = switch_on;
    }
    // console.log(this);
    return (
      <div>
      	<img alt="" onClick={this.props.toggleSwitch.bind(this, this.props.sw_id)} className="switch" src={image}/>
      </div>
    );
  }
}
 
export default Switch;