import React, { Component } from "react";
import catamaran from "./catamaran.JPG";
import "./About.css";
import {Helmet} from 'react-helmet-async';

class About extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Jonah Meggs | About</title>
          <meta name="description" content="Hey! My name is Jonah Meggs, I've just completed a double degree of Electrical Engineering and Computer Science,
         and am about to go teach English in Japan for a year." />
        </Helmet>
        <div className="cat">
          <img alt="Tim's masterpiece" src={catamaran}/>
        </div>
        <p>Hey! My name is Jonah Meggs, I've just completed a double degree of Electrical Engineering and Computer Science.
         I have a particular interest in UI and embedded systems. Outside of uni I love getting out and about, 
         whether that be on a boat, under the ground or with a pack on my shoulders. I'm currently making good 
         use of my degree by going and teaching English in Japan for a year.<br/><br/>
         Currently this site is mostly just a platform for access to DrongoSim (now obsolete) and NyanSim.
        </p>
      </div>
    );
  }
}
 
export default About;
