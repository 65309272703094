import React, { Component } from 'react';
import './LightSensor.css';
import lightSensor from './LightSensorBoard.svg';
 
export class LightSensor extends Component {
	constructor(props) {
    super(props);
    this.state = {
    	top: 0,
    	left: 0,
    	value: 1
    };
    this.moveShadow = this.moveShadow.bind(this);
  }

  moveShadow(e) {
    // if (this.state.pressed) {
    //   const currAngle = calcAngle((e.nativeEvent.offsetX-e.nativeEvent.target.clientWidth/2),(e.nativeEvent.offsetY-e.nativeEvent.target.clientHeight/2));
    //   const newAngle = bound(this.state.origAngle+currAngle-this.state.startAngle);
    //   this.setState({angle: newAngle});
    //   this.props.update(mapAngle(newAngle));
    // }
    // console.log(e.nativeEvent.offsetX/e.nativeEvent.target.clientWidth, e.nativeEvent.offsetY/e.nativeEvent.target.clientHeight);
    // 65%, 50%
    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;
    const cx = e.nativeEvent.target.clientWidth*0.65;
    const cy = e.nativeEvent.target.clientHeight*0.5;
    const dist = Math.sqrt((x-cx)*(x-cx) + (y-cy)*(y-cy));
    const value = dist/(0.025*window.innerWidth);
    // console.log(dist);
    if (value <= 1) {
    	// console.log("hmm");
    	this.props.update(value);
	    this.setState({
	    	left: e.nativeEvent.offsetX-0.025*window.innerWidth,
	    	top: e.nativeEvent.offsetY-0.025*window.innerWidth,
	    	value: value
	    });
    } else if (this.state.value !== 1) {
    	this.props.update(1);
    	this.setState({
	    	left: e.nativeEvent.offsetX-0.025*window.innerWidth,
	    	top: e.nativeEvent.offsetY-0.025*window.innerWidth,
	    	value: 1
	    });
    } else {
	    this.setState({
	    	left: e.nativeEvent.offsetX-0.025*window.innerWidth,
	    	top: e.nativeEvent.offsetY-0.025*window.innerWidth
	    });
    	// this.props.update(1);
    }
  }

  render() {
    //const style = {transform: `rotate(${this.state.angle}rad)`};
    return (
      <div className='light-sensor-container'>
        <object id='light-sensor' className='light-sensor' data={lightSensor} type="image/svg+xml" aria-label='Light Sensor Breakout Board'></object>
        <div className='light-sensor-dummy' onMouseMove={this.moveShadow}></div>
        <div id='light-sensor-shadow' className='light-sensor-shadow' style={{left: this.state.left, top: this.state.top}}></div>
      </div>
    );
  }
}
