import React, { Component } from 'react';
import './QVGA.css';
import {LED} from './LED';
import {DaughterButton} from './DaughterButton';
import {Potentiometer} from './Potentiometer';
import {LadderLED} from './LadderLED';
import {QVGALED} from './QVGALED';
// import button_on from "./button_on.png";
// import button_off from "./button_off.png";
 
export class QVGA extends Component {
  componentDidMount() {
    const canvas = document.getElementById('lcd');
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'rgb(255, 255, 255)';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;
    for (var i = 0; i < data.length; i += 4) {
        data[i]     = 255 - parseInt(Math.random()*255);     // red
        data[i + 1] = 255 - parseInt(Math.random()*255); // green
        data[i + 2] = 255 - parseInt(Math.random()*255); // blue
    }
    ctx.putImageData(imageData, 0, 0);
  }
  // }
  render() {
    const LCDStyle = {
      visibility: this.props.LCDenable ? 'visible' : 'hidden'
    };
    // const led1Style = {backgroundColor: (this.props.ladder&0x1) ? '#00FF00' : '#00000000'};
    // const led2Style = {backgroundColor: (this.props.ladder&0x2) ? '#00FF00' : '#00000000'};
    // const led3Style = {backgroundColor: (this.props.ladder&0x4) ? '#00FF00' : '#00000000'};
    // const led4Style = {backgroundColor: (this.props.ladder&0x8) ? '#00FF00' : '#00000000'};
    // const led5Style = {backgroundColor: (this.props.ladder&0x10) ? '#00FF00' : '#00000000'};
    // const led6Style = {backgroundColor: (this.props.ladder&0x20) ? 'orange' : '#00000000'};
    // const led7Style = {backgroundColor: (this.props.ladder&0x40) ? '#FF0000' : '#00000000'};
    // const led8Style = {backgroundColor: (this.props.ladder&0x80) ? '#FF0000' : '#00000000'};
    return (
      <div className='qvga-wrapper'>
        <div className='qvga-container' id='qvga-container'>
          <div className='qvga-content-wrapper'>
            <div className='daughter-ladder'>
              <LadderLED colour='#00FF00' enable={this.props.ladder&0x1}/>
              <LadderLED colour='#00FF00' enable={this.props.ladder&0x2}/>
              <LadderLED colour='#00FF00' enable={this.props.ladder&0x4}/>
              <LadderLED colour='#00FF00' enable={this.props.ladder&0x8}/>
              <LadderLED colour='#00FF00' enable={this.props.ladder&0x10}/>
              <LadderLED colour='orange' enable={this.props.ladder&0x20}/>
              <LadderLED colour='#FF0000' enable={this.props.ladder&0x40}/>
              <LadderLED colour='#FF0000' enable={this.props.ladder&0x80}/>
              {/*<div className='ladder-led-wrapper'><div className='ladder-led' id='ladder-led-1' style={led1Style}></div></div>
              <div className='ladder-led-wrapper'><div className='ladder-led' id='ladder-led-2' style={led2Style}></div></div>
              <div className='ladder-led-wrapper'><div className='ladder-led' id='ladder-led-3' style={led3Style}></div></div>
              <div className='ladder-led-wrapper'><div className='ladder-led' id='ladder-led-4' style={led4Style}></div></div>
              <div className='ladder-led-wrapper'><div className='ladder-led' id='ladder-led-5' style={led5Style}></div></div>
              <div className='ladder-led-wrapper'><div className='ladder-led' id='ladder-led-6' style={led6Style}></div></div>
              <div className='ladder-led-wrapper'><div className='ladder-led' id='ladder-led-7' style={led7Style}></div></div>
              <div className='ladder-led-wrapper'><div className='ladder-led' id='ladder-led-8' style={led8Style}></div></div>*/}
            </div>
            <div className='LEDbar' id='LEDbar'>
              <LED r={this.props.P3_16} g={this.props.P3_17} b={this.props.P3_18}/>
              <LED r={this.props.P3_19} g={this.props.P3_20} b={this.props.P3_21}/>
            </div>
            <DaughterButton name='P0_10' press={this.props.P0_10}/>
            <DaughterButton name='P0_11' press={this.props.P0_11}/>
            <canvas className='lcd' id='lcd' style={LCDStyle} width="240" height="320"></canvas>
            <Potentiometer left='92.7%' top='9.3%' name='speaker-volume' colour='#10adcc' colour2='#08839c' update={this.props.changeVolume}/>
            <Potentiometer left='69%' top='2.7%' name='adc-potentiometer' colour='#ed5a5a' colour2='#ab2727' update={this.props.changePotentiometer}/>
            <QVGALED left='37.1%' top='88%' enable={this.props.LED1}/>
            <QVGALED left='42.4%' top='88%' enable={this.props.LED2}/>
            <QVGALED left='47.8%' top='88%' enable={this.props.LED3}/>
            <QVGALED left='53.2%' top='88%' enable={this.props.LED4}/>
            <QVGALED left='37.1%' top='80%' enable={this.props.LED5}/>
            <QVGALED left='37.1%' top='81%' enable={this.props.LED6}/>
            <QVGALED left='37.1%' top='82%' enable={this.props.LED7}/>
            <QVGALED left='37.1%' top='83%' enable={this.props.LED8}/>
          </div>
        </div>
      </div>
    );
  }
}