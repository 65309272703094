export function vcc(inputs) {
    return [1];
}

export function gnd(inputs) {
    return [0];
}

export function buf(inputs) {
    return [inputs[0]];
}

export function inv(inputs) {
    return [inputs[0]^1];
}

export function and2(inputs){
    return [inputs[0]&inputs[1]];
}

export function and3(inputs){
    return [inputs.reduce((x,y) => {return x&y})];
}

export function and3b1(inputs){
    return [(inputs[0]^1)&inputs[1]&inputs[2]];
}

export function and3b2(inputs){
    return [(inputs[0]^1)&(inputs[1]^1)&inputs[2]];
}

export function and4(inputs){
    return [inputs.reduce((x,y) => {return x&y})];
}

export function and5(inputs){
    return [inputs.reduce((x,y) => {return x&y})];
}

export function and6(inputs){
    return [inputs.reduce((x,y) => {return x&y})];
}

export function and7(inputs){
    return [inputs.reduce((x,y) => {return x&y})];
}

export function and8(inputs){
    return [inputs.reduce((x,y) => {return x&y})];
}

export function and9(inputs){
    return [inputs.reduce((x,y) => {return x&y})];
}

export function or2(inputs){
    return [inputs[0]|inputs[1]];
}

export function or3(inputs){
    return [inputs.reduce((x,y) => {return x|y})];
}

export function or4(inputs){
    return [inputs.reduce((x,y) => {return x|y})];
}

export function or5(inputs){
    return [inputs.reduce((x,y) => {return x|y})];
}

export function or6(inputs){
    return [inputs.reduce((x,y) => {return x|y})];
}

export function or7(inputs){
    return [inputs.reduce((x,y) => {return x|y})];
}

export function or8(inputs){
    return [inputs.reduce((x,y) => {return x|y})];
}

export function or9(inputs){
    return [inputs.reduce((x,y) => {return x|y})];
}

export function or12(inputs){
    return [inputs.reduce((x,y) => {return x|y})];
}

export function or16(inputs){
    return [inputs.reduce((x,y) => {return x|y})];
}

export function nand2(inputs){
    return [inputs.reduce((x,y) => {return x&y})^1];
}

export function nand3(inputs){
    return [inputs.reduce((x,y) => {return x&y})^1];
}

export function nand4(inputs){
    return [inputs.reduce((x,y) => {return x&y})^1];
}

export function nand5(inputs){
    return [inputs.reduce((x,y) => {return x&y})^1];
}

export function nand6(inputs){
    return [inputs.reduce((x,y) => {return x&y})^1];
}

export function nand7(inputs){
    return [inputs.reduce((x,y) => {return x&y})^1];
}

export function nand3b3(inputs){
    return [((inputs[0]^1)&(inputs[1]^1)&(inputs[2]^1))^1];
}

export function nand4b4(inputs){
    return [((inputs[0]^1)&(inputs[1]^1)&(inputs[2]^1)&(inputs[3]^1))^1];
}

export function nand5b5(inputs){
    return [((inputs[0]^1)&(inputs[1]^1)&(inputs[2]^1)&(inputs[3]^1)&(inputs[4]^1))^1];
}

export function nor2(inputs){
    return [inputs.reduce((x,y) => {return x|y})^1];
}

export function nor3(inputs){
    return [inputs.reduce((x,y) => {return x|y})^1];
}

export function nor4(inputs){
    return [inputs.reduce((x,y) => {return x|y})^1];
}

export function nor5(inputs){
    return [inputs.reduce((x,y) => {return x|y})^1];
}

export function nor6(inputs){
    return [inputs.reduce((x,y) => {return x|y})^1];
}

export function nor7(inputs){
    return [inputs.reduce((x,y) => {return x|y})^1];
}

export function xor2(inputs){
    return [inputs.reduce((x,y) => {return x^y})];
}

export function xnor2(inputs){
    return [inputs.reduce((x,y) => {return x^y})^1];
}

export function m4_1e(inputs) {
	if (!inputs[4]) return [0];
    let index = 0;
    if (inputs[5]) index += 1;
    if (inputs[6]) index += 2;
    return [inputs[index]];
}

export function m8_1e(inputs) {
	if (!inputs[8]) return [0];
    let index = 0;
    if (inputs[9]) index += 1;
    if (inputs[10]) index += 2;
    if (inputs[11]) index += 4;
    return [inputs[index]];
}

export function d2_4e(inputs) {
	if (!inputs[2]) return [0];
    let index = 0;
    if (inputs[0]) index += 1;
    if (inputs[1]) index += 2;
    let ret = [0,0,0,0];
    ret[index] = 1;
    return ret;
}

export function d3_8e(inputs) {
	if (!inputs[3]) return [0];
    let index = 0;
    if (inputs[0]) index += 1;
    if (inputs[1]) index += 2;
    if (inputs[2]) index += 4;
    let ret = [0,0,0,0,0,0,0,0];
    ret[index] = 1;
    return ret;
}

export function d4_16e(inputs) {
    if (!inputs[4]) return [0];
    let index = 0;
    if (inputs[0]) index += 1;
    if (inputs[1]) index += 2;
    if (inputs[2]) index += 4;
    if (inputs[3]) index += 8;
    let ret = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    ret[index] = 1;
    return ret;
}

export function cb2ce(inputs) {
	if (inputs[2]) return [0,0,0,0,inputs[0]];
	if (!inputs[0] || inputs[0] === inputs[7] || !inputs[1]) return [inputs[3],inputs[4],inputs[5],inputs[6],inputs[0]];
	let res = 0;
	const rev = [inputs[4],inputs[5]];
	for (let i = 0; i < 2; i++) {
		res |= rev[i] << i;
	}
	const switcher = [[1,0],[0,1],[1,1],[0,0]];
	return [rev.reduce((x,y) => {return x|y})^1].concat(switcher[res], [(rev === [1,1] ? 1 : 0)], [inputs[0]]);
}

export function cb16ce(inputs) {
	let z = [];
	z.length = 18;
	z.fill(0,0,18);
	if (inputs[2]) return z.concat([inputs[0]]);
	if (!inputs[0] || inputs[0] === inputs[21] || !inputs[1]) return inputs.slice(3,21).concat([inputs[0]]);
	// console.log("cb16ce");
	let res = 0;
	const rev = inputs.slice(4,20);
	for (let i = 0; i < 16; i++) {
		res |= rev[i] << i;
	}
	res++;
	let out = [];
	for (let i = 0; i < 16; i++) {
		out.push(res%2);
		res = res >> 1;
	}
	// console.log(out);
	return [res].concat(out, [0,inputs[0]]);
}

export function cd4ce(inputs) {

	let z = [];
	z.length = 7;
	z.fill(0,0,7);
	if (inputs[2]) return z.concat([inputs[0]]);
	// console.log(`${inputs[0]} ${inputs[10]}`);
	if (!inputs[0] || inputs[0] === inputs[10] || !inputs[1]) return [0].concat(inputs.slice(4,10), [inputs[0]]);
	let res = 0;
	const rev = inputs.slice(4,9);
	// console.log("cd4ce");
	for (let i = 0; i < 4; i++) {
		res |= rev[i] << i;
	}
	const switcher = [
        [1,0,0,0,0],
        [0,1,0,0,0],
        [1,1,0,0,0],
        [0,0,1,0,0],
        [1,0,1,0,0],
        [0,1,1,0,0],
        [1,1,1,0,0],
        [0,0,0,1,0],
        [1,0,0,1,0],
        [0,0,0,0,1],
        [1,0,0,0,0]
    ];
    return [switcher[res][4]].concat(switcher[res].slice(0,5),[(inputs.slice(4,8) === [1,0,0,1]) ? 1 : 0],[inputs[0]]);
}

export function bcdtoseg(inputs) {
	let z = [];
	z.length = 7;
	const nRBO = ((inputs[6]^1)|((inputs[1]^1)&(inputs.slice(2,6).reduce((x, y) => {return x|y})^1)&inputs[0]))^1;
	if (!nRBO) {
		z.fill(1,0,7);
		return [nRBO].concat(z);
	} else if (!inputs[0]) {
		z.fill(0,0,7);
		return [nRBO].concat(z);
	}
	let res = 0;
	if (inputs[2]) res += 8;
    if (inputs[3]) res += 4;
    if (inputs[4]) res += 2;
    if (inputs[5]) res += 1;
    const switcher = [
        [0,0,0,0,0,0,1],
        [1,0,0,1,1,1,1],
        [0,0,1,0,0,1,0],
        [0,0,0,0,1,1,0],
        [1,0,0,1,1,0,0],
        [0,1,0,0,1,0,0],
        [0,1,0,0,0,0,0],
        [0,0,0,1,1,1,1],
        [0,0,0,0,0,0,0],
        [0,0,0,0,1,0,0],
        [1,1,1,0,0,1,0],
        [1,1,0,0,1,1,0],
        [1,0,1,1,1,0,0],
        [0,1,1,0,1,0,0],
        [1,1,1,0,0,0,0],
        [1,1,1,1,1,1,1]
    ];
    return [nRBO].concat(switcher[res]);
}