import React, { Component } from "react";
import "./LED.css";
 
class LED extends Component {
  render() {
  	let clr= (this.props.LED) ? "yellow" : "grey";
    return (
      <div className="Led" style={{backgroundColor: clr}}>
      </div>
    );
  }
}
 
export default LED;