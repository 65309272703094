import React, { Component } from 'react';
import './QVGA.css';
 
export class LadderLED extends Component {

  render() {
    const ledStyle = {backgroundColor: (this.props.enable) ? this.props.colour : '#00000000'};
    return (
      <div className='ladder-led-wrapper'><div className='ladder-led' style={ledStyle}></div></div>
    );
  }
}