import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import "./Table.css";
import {HelmetProvider} from 'react-helmet-async';
import Main from "./Main";
// import Login from "./pages/Login";
// import Register from "./pages/Register";
import "./App.css";

class App extends Component {
  render() {
    return (
      <HelmetProvider>
      <Router>
        <Switch>
          <Route exact path="/" component={Main}/>
          <Route exact path="/drongosim" component={Main}/>
          <Route exact path="/nyansim" component={Main}/>
          <Route exact path="/about" component={Main}/>
          <Route exact path="/contact" component={Main}/>
        </Switch>
      </Router>
      </HelmetProvider>
    );
  }
}
 
export default App;

// <Route exact path="/forgot_password" component={ForgotPasswordPage} />
          // <Route exact path="/reset_password" component={ResetPasswordPage} />
          // <ProtectedRoute exact path="/" component={HomePage} />
          // <ProtectedRoute path="/profile/:profile" component={ProfilePage} />
          // <ProtectedRoute path="/channel/:channel_id" component={ChannelPage} />
          // <ProtectedRoute path="/search/:query_str" component={SearchPage} />
          // <ProtectedRoute path="/search" component={SearchPage} />