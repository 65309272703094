import React, { Component } from 'react';
import './QVGA.css';
import button from './button.svg';
// import button_off from "./button_off.png";
 
export class DaughterButton extends Component {
	constructor(props) {
    super(props); // Not sure I need this
    this.press = this.press.bind(this);
    this.release = this.release.bind(this);
  }

  press() {
    this.props.press(1);
    let elem = document.querySelector(`#${this.props.name}_button`);
    let button = elem.contentDocument.getElementById('button');
    button.setAttribute('stroke-width', '1');
  }

  release() {
    this.props.press(0);
    let elem = document.querySelector(`#${this.props.name}_button`);
    let button = elem.contentDocument.getElementById('button');
    button.setAttribute('stroke-width', '3');
  }

  render() {
    return (
      <div id={this.props.name} className='daughter-button-wrapper' onMouseDown={this.press} onMouseUp={this.release}>
        <object id={`${this.props.name}_button`} className='daughter-button' data={button} type='image/svg+xml' aria-label='Daughterboard Button'></object>
      </div>
    );
  }
}