import React, { Component } from 'react';
import './Potentiometer.css';
import potentiometer from './potentiometer.svg';

function calcAngle(x, y) {
  if (x === 0) return y >= 0 ? Math.PI/2 : 3*Math.PI/2;
  if (x < 0 && y >= 0) return Math.atan(y/x) + Math.PI;
  if (x < 0 && y < 0) return Math.atan(y/x) + Math.PI;
  if (x > 0 && y < 0) return 2*Math.PI + Math.atan(y/x);
  return Math.atan(y/x);
}

function bound(angle) {
  while (angle > 2*Math.PI) {angle -= 2*Math.PI}
  while (angle < 0) {angle += 2*Math.PI}
  if (angle > Math.PI/4 && angle <= Math.PI/2) return Math.PI/4;
  if (angle > Math.PI/2 && angle < 3*Math.PI/4) return 3*Math.PI/4;
  return angle;
}

function mapAngle(angle) {
  if (angle < Math.PI/2) return (angle+5*Math.PI/4)/(3*Math.PI/2);
  return (angle-3*Math.PI/4)/(3*Math.PI/2);
}
 
export class Potentiometer extends Component {
	constructor(props) {
    super(props); // Not sure I need this
    this.state = {
      pressed: false,
      origAngle: 0,
      angle: 3*Math.PI/2,
      startAngle: 0
    };
    this.press = this.press.bind(this);
    this.release = this.release.bind(this);
    this.rotate = this.rotate.bind(this);
    this.setColour = this.setColour.bind(this);
  }

  press(e) {
    const startAngle = calcAngle((e.nativeEvent.offsetX-e.nativeEvent.target.clientWidth/2),(e.nativeEvent.offsetY-e.nativeEvent.target.clientHeight/2));
    this.setState({
      pressed: true,
      startAngle: startAngle,
      origAngle: this.state.angle
    });
  }

  release(e) {
    this.setState({pressed: false});
  }

  rotate(e) {
    if (this.state.pressed) {
      const currAngle = calcAngle((e.nativeEvent.offsetX-e.nativeEvent.target.clientWidth/2),(e.nativeEvent.offsetY-e.nativeEvent.target.clientHeight/2));
      const newAngle = bound(this.state.origAngle+currAngle-this.state.startAngle);
      this.setState({angle: newAngle});
      this.props.update(mapAngle(newAngle));
    }
  }

  setColour() {
    const elem = document.querySelector(`#${this.props.name}`);
    elem.contentDocument.getElementById("colour1").setAttribute("fill", this.props.colour);
    elem.contentDocument.getElementById("colour2").setAttribute("fill", this.props.colour2);
    elem.contentDocument.getElementById("colour3").setAttribute("fill", this.props.colour2);
  }

  render() {
    const style = {transform: `rotate(${this.state.angle}rad)`};
    const wrapperStyle = {left: this.props.left, top: this.props.top};
    return (
      <div className='potentiometer-wrapper' style={wrapperStyle}>
        <object id={this.props.name} className='potentiometer' style={style} data={potentiometer} type="image/svg+xml" onLoad={this.setColour} aria-label='Potentiometer'></object>
        <div id={this.props.name+'_dummy'} className='potentiometer-dummy' onMouseDown={this.press} onMouseUp={this.release} onMouseMove={this.rotate}></div>
      </div>
    );
  }
}