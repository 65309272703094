import React, { Component } from "react";
import email_img from "./email.png";
import "./Contact.css";
import {Helmet} from 'react-helmet-async';

class Contact extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Jonah Meggs | Contact</title>
          <meta name="description" content="Questions, queries, complaints or concerns? I'd love to hear about it!" />
        </Helmet>
        <div>
          <img className="email" alt="email" src={email_img}/>
        </div>
        <p className="text">Questions, queries, complaints or concerns?<br/>
        I'd love to hear about it (especially if it's a bug!)</p>
      </div>
    );
  }
}
 
export default Contact;
