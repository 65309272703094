import React, { Component } from "react";
import "./Button.css";
import button_on from "./button_on.png";
import button_off from "./button_off.png";
 
class Button extends Component {
	// constructor(props) {
 //    super(props);
 
 //    // this.state = {
 //    //   on: 0
 //    // };
 //    // this.toggleButton = this.toggleButton.bind(this);
 //  }

  // toggleButton(e) {
  //   this.setState({
  //     on: this.state.on ^ 1
  //   });

  // }
  render() {
    let image = (this.props.BTN) ? button_on : button_off;
    return (
      <div>
      	<img alt="" onMouseDown={this.props.toggleButton.bind(this, this.props.btn_id)} onMouseUp={this.props.toggleButton.bind(this, this.props.btn_id)} className="button_fpga" src={image}/>
      </div>
    );
  }
}
 
export default Button;