import React, { Component } from 'react';
import './Register.css';

export class Register extends Component {
  constructor(props) {
    super(props);
 
    this.state = {

    };
  }

  render() {
    let str = this.props.text+':'+(' '.repeat(5-this.props.text.length))+'0x';
    for (let i = 0; i < 8-(this.props.value>>>0).toString(16).length; i++) {
      str += '0';
    }
    str += (this.props.value>>>0).toString(16);
    return (
      <div className='register-container'>{str}</div>
    );
  }
}