import React, { Component } from 'react';
import './QVGA.css';
 
export class QVGALED extends Component {

  render() {
    const ledStyle = {backgroundColor: (this.props.enable) ? '#00000000' : '#FF0000'};
    const wrapperStyle = {
      top: this.props.top,
      left: this.props.left
    };
    return (
      <div className='qvga-led-wrapper' style={wrapperStyle}><div className='ladder-led' style={ledStyle}></div></div>
    );
  }
}