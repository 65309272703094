import React, { Component } from 'react';
import './QVGA.css';
 
export class LED extends Component {

  render() {
    let LEDStyle = {};
    if (this.props.r === this.props.g && this.props.g === this.props.b && this.props.b === 0) {
      LEDStyle = {
        opacity: '0'
      }
    } else {
      LEDStyle = {
        opacity: '1',
        backgroundColor: `#${this.props.r?'FF':'00'}${this.props.g?'FF':'00'}${this.props.b?'FF':'00'}`
      };
    }
    return (
      <div className='QVGAled' style={LEDStyle}></div>
    );
  }
}