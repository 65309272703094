import React, { Component } from "react";
import background from "./fadedDrongo.png";
// import cocoa from "./CocoaDrawing.png";
import "./Drongo.css";
import {Helmet} from 'react-helmet-async';
 
class Drongo extends Component {
  render() {
    return (
      <div className="bimg">
        <Helmet>
          <title>Jonah Meggs</title>
          <meta name="description" content="Personal site of Jonah Meggs" />
        </Helmet>
        <img alt="Career of Drongo" src={background}/>
        <span className="attribution">Image courtesy of <a href="https://trove.nla.gov.au/newspaper/article/129728613#">Trove</a></span>
      </div>
    );
  }
}
 
export default Drongo;


// <img alt="Career of Drongo" src={background}/>
// <span className="attribution">Image courtesy of <a href="https://trove.nla.gov.au/newspaper/article/129728613#">Trove</a></span>

// <img className="Cocoa" alt="Cocoa" src={cocoa}/>
// <span className="attribution">🖤 27/5/20</span>
