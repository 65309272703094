import React, { Component } from "react";
import {
  Route,
  NavLink
} from "react-router-dom";
import Drongo from "./Drongo";
import DrongoSim from "./DrongoSim";
import NyanSim from "./NyanSim";
import About from "./About";
import Contact from "./Contact";
import './Main.css'

class Main extends Component {
  render() {
    return (
      <div className="grid">
        <div className="menu">
          <h1><NavLink to="/">JM</NavLink></h1>
          <div className="subpages">
            <h2><NavLink to="/drongosim">DrongoSim</NavLink></h2>
            <h2><NavLink to="/nyansim">NyanSim</NavLink></h2>
            <h2><NavLink to="/about">About</NavLink></h2>
            <h2><NavLink to="/contact">Contact</NavLink></h2>
          </div>
        </div>
        <React.StrictMode>
        <div className="content">
          <Route exact path="/" component={Drongo}/>
          <Route path="/drongosim" component={DrongoSim}/>
          <Route path="/nyansim" component={NyanSim}/>
          <Route path="/about" component={About}/>
          <Route path="/contact" component={Contact}/>
        </div>
        </React.StrictMode>
      </div>
    );
  }
}
 
export default Main;
