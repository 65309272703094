// Pins: Output
export function vcc(file_lines, i, block) {
	const block_regex = /<blockpin signalname="([\w|(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+1;
}

// Pins: Output
export function gnd(file_lines, i, block) {
	const block_regex = /<blockpin signalname="([\w|(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+1;
}

// Pins: Input, Output
export function buf(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w|(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+2;
}

// Pins: Input, Output
export function inv(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w|(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+2;
}

// Pins: 2*Input, Output
export function and2(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+3;
}

// Pins: 3*Input, Output
export function and3(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+4;
}

// Pins: 3*Input (first inverted), Output
export function and3b1(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+4;
}

// Pins: 3*Input (first 2 inverted), Output
export function and3b2(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+4;
}

// Pins: 4*Input, Output
export function and4(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+5;
}

// Pins: 5*Input, Output
export function and5(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+6;
}

// Pins: 6*Input, Output
export function and6(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+7].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+7;
}

// Pins: 7*Input, Output
export function and7(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+7].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+8].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+8;
}

// Pins: 8*Input, Output
export function and8(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+7].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+8].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+9].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+9;
}

// Pins: 9*Input, Output
export function and9(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+7].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+8].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+9].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+10].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+10;
}

// Pins are 2*Input, Output
export function or2(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w|(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+3;
}

// Pins: 3*Input, Output
export function or3(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+4;
}

// Pins: 4*Input, Output
export function or4(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+5;
}

// Pins: 5*Input, Output
export function or5(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+6;
}

// Pins: 6*Input, Output
export function or6(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+7].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+7;
}

// Pins: 7*Input, Output
export function or7(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+7].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+8].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+8;
}

// Pins: 8*Input, Output
export function or8(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+7].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+8].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+9].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+9;
}

// Pins: 9*Input, Output
export function or9(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+7].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+8].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+9].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+10].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+10;
}

// Pins: 12*Input, Output
export function or12(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+7].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+8].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+9].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+10].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+11].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+12].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+13].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+13;
}

// Pins: 16*Input, Output
export function or16(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+7].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+8].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+9].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+10].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+11].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+12].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+13].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+14].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+15].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+16].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+17].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+17;
}

// Pins: 2*Input, Output
export function nand2(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+3;
}

// Pins: 3*Input, Output
export function nand3(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+4;
}

// Pins: 4*Input, Output
export function nand4(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+5;
}

// Pins: 5*Input, Output
export function nand5(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+6;
}

// Pins: 6*Input, Output
export function nand6(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+7].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+7;
}

// Pins: 7*Input, Output
export function nand7(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+7].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+8].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+8;
}

// Pins: 3*Input, Output
export function nand3b3(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+4;
}

// Pins: 4*Input, Output
export function nand4b4(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+5;
}

// Pins: 5*Input, Output
export function nand5b5(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+6;
}

// Pins are 2*Input, Output
export function nor2(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w|(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+3;
}

// Pins: 3*Input, Output
export function nor3(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+4;
}

// Pins: 4*Input, Output
export function nor4(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+5;
}

// Pins: 5*Input, Output
export function nor5(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+6;
}

// Pins: 6*Input, Output
export function nor6(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+7].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+7;
}

// Pins: 7*Input, Output
export function nor7(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+7].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+8].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+8;
}

// Pins are 2*Input, Output
export function xor2(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w|(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+3;
}

// Pins are 2*Input, Output
export function xnor2(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w|(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+3;
}

// Pins: 4*Input, Enable, 2*Select, Output
export function m4_1e(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+7].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+8].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+8;
}

// Pins: 8*Input, Enable, 3*Select, Output
export function m8_1e(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+7].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+8].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+9].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+10].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+11].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+12].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+13].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+13;
}

// Pins are 2*Input, Enable, 4*Output
export function d2_4e(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w|(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+5].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+6].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+7].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+7;
}

// Pins: 3*inputs, Enable, 8*Outputs. Note Outputs are ordered in decimal order ie 0,1,10,11,12, etc
export function d3_8e(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w|(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+6].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+7].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+8].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+9].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+10].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+11].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+12].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+12;
}

// Pins: 4*inputs, Enable, 16*Outputs. Note Outputs are ordered in decimal order ie 0,1,10,11,12, etc
export function d4_16e(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w|(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+7].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+14].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+15].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+16].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+17].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+18].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+19].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+20].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+21].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+8].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+9].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+10].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+11].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+12].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+13].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+21;
}

// Pins are Clock, Enable, Clear, Carry out, 2*Outputs, TC
export function cb2ce(file_lines, i, block){
	let str;
	if (typeof cb2ce.counter === 'undefined') {
        cb2ce.counter = 0;
    }
	let block_regex = /<blockpin signalname="([\w|(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	if (ret !== null) str = ret[1];
	// console.log("In cb2ce:");
	// console.log(JSON.parse(JSON.stringify(ret)));
	block.state.push((ret !== null) ? {[str]:0} : null);
	// console.log(JSON.parse(JSON.stringify(block.state)));
	ret = file_lines[i+5].match(block_regex);
	if (ret !== null) str = ret[1];
	block.state.push((ret !== null) ? {[str]:0} : null);
	ret = file_lines[i+6].match(block_regex);
	if (ret !== null) str = ret[1];
	block.state.push((ret !== null) ? {[str]:0} : null);
	ret = file_lines[i+7].match(block_regex);
	if (ret !== null) str = ret[1];
	block.state.push((ret !== null) ? {[str]:0} : null);
	const clk_prev = `cb2ce_clk_prev${cb2ce.counter}`;
	block.state.push({[clk_prev]:0});
	cb2ce.counter++;
	return i+7;
}

// Pins are Clock, Enable, Clear, Carry out, output bus (15:0), TC
export function cb16ce(file_lines, i, block){
	let str;
	if (typeof cb16ce.counter === 'undefined') {
        cb16ce.counter = 0;
    }
	let block_regex = /<blockpin signalname="([\w|(|)]+)"/;
	let bus_regex = /<blockpin signalname="(\w+)\(15:0\)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	if (ret !== null) str = ret[1];
	block.state.push((ret !== null) ? {[str]:0} : null);
	ret = file_lines[i+5].match(bus_regex);
	let tmp;
	for (let j = 0; j < 16; j++) {
		if (ret !== null) tmp = ret[1]+`(${j})`;
		(ret !== null) ? block.state.push({[tmp]:0}) : block.state.push(null);
	}
	ret = file_lines[i+6].match(block_regex);
	if (ret !== null) str = ret[1];
	block.state.push((ret !== null) ? {[str]:0} : null);
	const clk_prev = `cb16ce_clk_prev${cb16ce.counter}`;
	block.state.push({[clk_prev]:0});
	cb16ce.counter++;
	return i+6;
}

// Pins are Clock, Enable, Clear, Carry out, 4*Outputs, TC
export function cd4ce(file_lines, i, block){
	let str;
	if (typeof cd4ce.counter === 'undefined') {
        cd4ce.counter = 0;
    }
	let block_regex = /<blockpin signalname="([\w|(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	if (ret !== null) str = ret[1];
	block.state.push((ret !== null) ? {[str]:0} : null);
	ret = file_lines[i+5].match(block_regex);
	if (ret !== null) str = ret[1];
	block.state.push((ret !== null) ? {[str]:0} : null);
	ret = file_lines[i+6].match(block_regex);
	if (ret !== null) str = ret[1];
	block.state.push((ret !== null) ? {[str]:0} : null);
	ret = file_lines[i+7].match(block_regex);
	if (ret !== null) str = ret[1];
	block.state.push((ret !== null) ? {[str]:0} : null);
	ret = file_lines[i+8].match(block_regex);
	if (ret !== null) str = ret[1];
	block.state.push((ret !== null) ? {[str]:0} : null);
	const carry = `hidden_carry_cd4ce${cd4ce.counter}`;
	block.state.push({[carry]:0});
	ret = file_lines[i+9].match(block_regex);
	if (ret !== null) str = ret[1];
	block.state.push((ret !== null) ? {[str]:0} : null);
	const clk_prev = `cd4ce_clk_prev${cd4ce.counter}`;
	block.state.push({[clk_prev]:0});
	cd4ce.counter++;
	return i+9;
}

// Pins are nLT, nRBI, 4*inputs, nBI, nRBO, 7*Outputs
export function bcdtoseg(file_lines, i, block) {
	let block_regex = /<blockpin signalname="([\w(|)]+)"/;
	let ret = file_lines[i+1].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+2].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+3].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+4].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+5].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+6].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+7].match(block_regex);
	block.in.push(ret[1]);
	ret = file_lines[i+8].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+9].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+10].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+11].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+12].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+13].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+14].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	ret = file_lines[i+15].match(block_regex);
	(ret !== null) ? block.out.push(ret[1]) : block.out.push(null);
	return i+15;
}