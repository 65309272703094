import React, { Component } from "react";
import Seg from "./Seg";
import LED from "./LED";
import Switch from "./Switch";
import Button from "./Button";
import "./fpga.css";


// import switch_off from "./switch_off.png";
import switch_on from "./switch_on.png";
import button_off from "./button_off.png";
// import button_on from "./button_on.png";
import xilinx from "./xilinx.png";
import nexys from "./nexys.png";
import digilent from "./digilent.png";
 
class FPGA extends Component {
  render() {
    return (
      <div className="board">
      	<div className="power"></div>
      	<div className="pmod" id="pmod1"></div>
      	<div className="pmod" id="pmod2"></div>
      	<div className="pmod" id="pmod3"></div>
      	<div className="pmod" id="pmod4"></div>
      	<img alt="" className="powerSwitch" src={switch_on}/>
      	<div className="uUSB"></div>
      	<img alt="" className="xilinx" src={xilinx}/>
      	<img alt="" className="reset" src={button_off}/>
      	<div className="dpi"></div>
      	<div className="ethernet"></div>
      	<div className="chip"></div>
      	<img alt="" className="nexys" src={nexys}/>
      	<div className="vga"></div>
      	<div id="AN3"><Seg AN={this.props.AN[3]} SEG={this.props.SEG}/></div>
      	<div id="AN2"><Seg AN={this.props.AN[2]} SEG={this.props.SEG}/></div>
      	<div id="AN1"><Seg AN={this.props.AN[1]} SEG={this.props.SEG}/></div>
      	<div id="AN0"><Seg AN={this.props.AN[0]} SEG={this.props.SEG}/></div>
      	<img alt="" className="digilent" src={digilent}/>
      	<div className="USB"></div>
      	<div id="grp7">
      	  <div className="led"><LED LED={this.props.LED[7]}/></div>
      	  <p className="switchLabel">SW7</p>
      	</div>
      	<div id="grp6">
      	  <div className="led"><LED LED={this.props.LED[6]}/></div>
      	  <p className="switchLabel">SW6</p>
      	</div>
      	<div id="grp5">
      	  <div className="led"><LED LED={this.props.LED[5]}/></div>
      	  <p className="switchLabel">SW5</p>
      	</div>
      	<div id="grp4">
      	  <div className="led"><LED LED={this.props.LED[4]}/></div>
      	  <p className="switchLabel">SW4</p>
      	</div>
      	<div id="grp3">
      	  <div className="led"><LED LED={this.props.LED[3]}/></div>
      	  <p className="switchLabel">SW3</p>
      	</div>
      	<div id="grp2">
      	  <div className="led"><LED LED={this.props.LED[2]}/></div>
      	  <p className="switchLabel">SW2</p>
      	</div>
      	<div id="grp1">
      	  <div className="led"><LED LED={this.props.LED[1]}/></div>
      	  <p className="switchLabel">SW1</p>
      	</div>
      	<div id="grp0">
      	  <div className="led"><LED LED={this.props.LED[0]}/></div>
      	  <p className="switchLabel">SW0</p>
      	</div>
	  	<div id="SW7"><Switch SW={this.props.SW[7]} toggleSwitch={this.props.toggleSwitch} sw_id={7}/></div>
      	<div id="SW6"><Switch SW={this.props.SW[6]} toggleSwitch={this.props.toggleSwitch} sw_id={6}/></div>
      	<div id="SW5"><Switch SW={this.props.SW[5]} toggleSwitch={this.props.toggleSwitch} sw_id={5}/></div>
      	<div id="SW4"><Switch SW={this.props.SW[4]} toggleSwitch={this.props.toggleSwitch} sw_id={4}/></div>
      	<div id="SW3"><Switch SW={this.props.SW[3]} toggleSwitch={this.props.toggleSwitch} sw_id={3}/></div>
      	<div id="SW2"><Switch SW={this.props.SW[2]} toggleSwitch={this.props.toggleSwitch} sw_id={2}/></div>
      	<div id="SW1"><Switch SW={this.props.SW[1]} toggleSwitch={this.props.toggleSwitch} sw_id={1}/></div>
      	<div id="SW0"><Switch SW={this.props.SW[0]} toggleSwitch={this.props.toggleSwitch} sw_id={0}/></div>
      	<div id="BTNU"><Button BTN={this.props.BTN[0]} toggleButton={this.props.toggleButton} btn_id={0}/></div>
      	<div id="BTNL"><Button BTN={this.props.BTN[1]} toggleButton={this.props.toggleButton} btn_id={1}/></div>
      	<div id="BTNS"><Button BTN={this.props.BTN[2]} toggleButton={this.props.toggleButton} btn_id={2}/></div>
      	<div id="BTNR"><Button BTN={this.props.BTN[3]} toggleButton={this.props.toggleButton} btn_id={3}/></div>
      	<div id="BTND"><Button BTN={this.props.BTN[4]} toggleButton={this.props.toggleButton} btn_id={4}/></div>
      </div>
    );
  }
}
 
export default FPGA;