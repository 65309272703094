import React, { Component } from "react";
// import FPGA from "./sim/fpga";
// import * as read_modules from "./sim/read_modules";
// import * as modules from "./sim/modules";
import "./NyanSim.css";
import {Helmet} from 'react-helmet-async';
import {Register} from './Register';
import {QVGA} from './QVGA';
import {LightSensor} from './LightSensor';
// eslint-disable-next-line
import Worker from 'worker-loader!./cpu.js';

let strReg = ['R0', 'R1', 'R2', 'R3', 'R4', 'R5', 'R6', 'R7', 'R8', 'R9', 'R10', 'R11', 'R12', 'SP', 'LR', 'PC', 'CPSR', 'SPSR'];

let AudioContext = window.AudioContext || window.webkitAudioContext;
let audioCtx = new AudioContext();
let gain = audioCtx.createGain();
gain.gain.value = 0.125/25;
let bufferQueue = [];
let playing = false;

function playAudio() {
  if (bufferQueue.length === 0) {
    playing = false;
    return;
  }
  // console.log('Playing (from play)');
  // let source = audioCtx.createBufferSource();
  // set the buffer in the AudioBufferSourceNode
  let source = bufferQueue.shift();
  // connect the AudioBufferSourceNode to the
  // destination so we can hear the sound
  // source.connect(audioCtx.destination);
  // start the source playing
  source.start();
  source.onended = () => {
    playAudio();
  }
}

function queueAudio(source) {
  // console.log('Queueing Audio!');
  if (!playing) {
    // console.log('Playing (from queue)');
    playing = true;
    source.start();
    source.onended = () => {
      playAudio();
    }
  } else {
    bufferQueue.push(source);
  }
}

let currentCursor = 0;

function setCursor(target, pc) {
  // console.log("hello! "+currentCursor);
  let old = document.getElementById(`instr${currentCursor}`);
  // let elem = document.getElementById(`instr${target}`);
  if (pc>>2 !== currentCursor+2) old.style.backgroundColor = 'white';
  if (pc>>2 !== target.id.slice(5)+2) target.style.backgroundColor = 'lightblue';
  currentCursor = parseInt(target.id.slice(5));
}

function setPCHighlight(oldPC, newPC) {
  // console.log((oldPC>>2) - 2, (newPC>>2) - 2)
  let old = document.getElementById(`instr${(oldPC>>2) - 2}`);
  let n = document.getElementById(`instr${(newPC>>2) - 2}`);
  if (old) old.style.backgroundColor = 'white';
  if (n) n.style.backgroundColor = 'yellow';
  oldPCHighlighted = newPC;
}

function checkELF(contents) {
  return contents.charCodeAt(0)===0x7f&&contents.charCodeAt(1)===0x45&&contents.charCodeAt(2)===0x4c&&contents.charCodeAt(3)===0x46;
}

let oldHighlighted = undefined;
let oldPCHighlighted = undefined;
let bufferHysteresis = 20;

function refreshLCD(queue) {
  // console.log("Refreshing!");
  const canvas = document.getElementById('lcd');
  const ctx = canvas.getContext('2d');
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  // console.log(canvas.width, canvas.height);
  const data = imageData.data;
  const scale1 = parseInt(255/0x1F);
  const scale2 = parseInt(255/0x3F);
  queue.forEach((update) => {
      data[update.index*4]     = ((update.value>>11)&0x1F)*scale1;     // red
      data[update.index*4 + 1] = ((update.value>>5)&0x3F)*scale2; // green
      data[update.index*4 + 2] = (update.value&0x1F)*scale1; // blue

  });
  ctx.putImageData(imageData, 0, 0);
}

let touching = 0;
let touchX = 0;
let touchY = 0;
let tscLastCalc = 0;

function updateTouch(e) {
  var flags = e.buttons !== undefined ? e.buttons : e.which;
  touching = (flags & 1) === 1;
  if (touching) {
    touchX = e.offsetX;
    touchY = e.offsetY;
    // console.log(touchX, touchY);
  }
}

const z1Grad = [0.165178571, 0.254464286, 0.267857143, 0.28125, 0.303571429, 0.325892857, 0.357142857, 0.401785714, 0.294642857];
const z2Grad = [0.546875, 0.510416667, 0.5, 0.479166667, 0.46875, 0.458333333, 0.442708333, 0.447916667, 0.421875];
const z2Start = [100, 107, 113, 121, 125, 128, 132, 131, 144];


// TODO: implement 12-bit shenanigans, and SER/DFR check
function tscCalculate(controlByte) {
  const canvas = document.getElementById('lcd');
  // console.log(canvas.offsetWidth, canvas.offsetHeight);
  const a = (controlByte>>4)&0x7;
  const x = Math.round(255*touchX/canvas.offsetWidth);
  const y = Math.round(255*touchY/canvas.offsetHeight);
  if (a === 1) { // Measure Y
    if (touching) {
      // console.log('y is:         ', Math.round(255*touchY/canvas.offsetHeight));
      return y;
    } else {
      return 240 + Math.round(Math.random()*20)-10;
    }
  } else if (a === 3) { // Measure Z1
    if (!touching) return 0;
    const lowIndex = Math.floor(y/32);
    const highIndex = Math.ceil(y/32);
    const lower = x*z1Grad[lowIndex];
    const higher = x*z1Grad[highIndex];
    return lower + (higher-lower)*((y%32)/32);
  } else if (a === 4) { // TODO Measure Z2
    if (!touching) return 255;
    const lowIndex = Math.floor(x/32);
    const highIndex = Math.ceil(x/32);
    const lower = y*z2Grad[lowIndex] + z2Start[lowIndex];
    const higher = y*z2Grad[highIndex] + z2Start[highIndex];
    return lower + (higher-lower)*((x%32)/32);
  } else if (a === 5) { // Measure X
    if (touching) {
      // console.log('x is: ', Math.round(255*touchX/canvas.offsetWidth));
      return x;
    } else {
      return 0;
    }
  } else { // Not defined, should probs research this, return 0 for now
    return 0;
  }
}

let cpu = undefined;
let elf_contents = undefined;
let running = false;

// DAC
let prevDacUpdate = undefined;
let prevDacValue = 0;

let lightSensor = 1023; // TODO properly reset these
let potentiometer = 512;
let i2c = -2;
let i2cAddress = 0;
let LEDDimmerState = -1;
let LEDDimmerRegister = 0;

class NyanSim extends Component {
  constructor(props) {
    super(props);
 
    this.state = { //Registers can't be here for much longer, cause otherwise each cycle has to 
      d_R0: 0,        // wait for the state to update :/ move them out to a global var with getters and
      d_R1: 0,        // setters, and only update this state (tied to Register components) when stepping or 
      d_R2: 0,        // reached end of runToCursor
      d_R3: 0,
      d_R4: 0,
      d_R5: 0,
      d_R6: 0,
      d_R7: 0,
      d_R8: 0,
      d_R9: 0,
      d_R10: 0,
      d_R11: 0,
      d_R12: 0,
      d_SP: 0,
      d_LR: 0,
      d_PC: 8,
      d_CPSR: 0,
      d_SPSR: 0,
      instructionList: [],
      P3_16: 0,
      P3_17: 0,
      P3_18: 0,
      P3_19: 0,
      P3_20: 0,
      P3_21: 0,
      LCDenable: false,
      clk: 0,
      ladder: 0,
      ladderEn: 0,
      LED1: 1,
      LED2: 1,
      LED3: 1,
      LED4: 1,
      LED5: 1,
      LED6: 1,
      LED7: 1,
      LED8: 1
    };
 
    this.readFile = this.readFile.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.runToCursor = this.runToCursor.bind(this);
    this.displayRegisters = this.displayRegisters.bind(this);
    this.reset = this.reset.bind(this);
    this.run = this.run.bind(this);
    this.stop = this.stop.bind(this);
    this.step = this.step.bind(this);
    this.P0_10 = this.P0_10.bind(this);
    this.P0_11 = this.P0_11.bind(this);
    this.changeVolume = this.changeVolume.bind(this);
    this.changePotentiometer = this.changePotentiometer.bind(this);
    this.changeLightSensor = this.changeLightSensor.bind(this);
    this.updateInstructions = this.updateInstructions.bind(this);
    this.myDisplayRegisters = this.myDisplayRegisters.bind(this);
    this.cpu_handler = this.cpu_handler.bind(this);
    this.goToReadme = this.goToReadme.bind(this);
  }

  componentDidMount() {
    document.getElementById("fileUpload").addEventListener("change", this.readFile);
    const canvas = document.getElementById('lcd');
    canvas.addEventListener("mousedown", updateTouch);
    canvas.addEventListener("mousemove", updateTouch);
    canvas.addEventListener("mouseup", updateTouch);
    if (!audioCtx) {
      audioCtx = new AudioContext();
    }
  }
 
  componentWillUnmount() {
    document.getElementById("fileUpload").removeEventListener("change", this.readFile);
    const canvas = document.getElementById('lcd');
    canvas.removeEventListener("mousedown", updateTouch);
    canvas.removeEventListener("mousemove", updateTouch);
    canvas.removeEventListener("mouseup", updateTouch);
    if (cpu) {
      console.log('Terminating worker');
      cpu.terminate();
      cpu = undefined;
      running = false;
    }
    if (audioCtx) {
      audioCtx.close();
      audioCtx = undefined;
    }
  }

  updateInstructions(obj) {
    this.setState(obj);
  }

  myDisplayRegisters(arr) {
    this.displayRegisters(arr);
  }

  cpu_handler(e) {
    // console.log('Received!');
    // console.log(e);
    if (e.data.type === 'update-registers') {
      running = false;
      setPCHighlight(oldPCHighlighted, e.data.registers[15])
      // if (e.data.cursor) setCursor(e.data.cursorValue, e.data.registers[15]);
      this.myDisplayRegisters(e.data.registers);
    } else if (e.data.type === 'dac') {
      // console.log('DAC');
      const now = Date.now();
      // Determine the duration of the sent audio data
      let duration = prevDacUpdate?now-prevDacUpdate:500;
      if (duration > 550) duration = 550;
      // Estimat eclock frequency
      const freq = e.data.cycles*1000/duration;
      // Create a source array
      const arr = e.data.queue;
      const buffer = [];
      let currCycles = 0;
      for (let i = 0; i < arr.length; i++) { // TODO: can I optimise this?? perhaps by using a sample rate like 441 and then allowing the context to resample it?
        let frameCount = Math.round(audioCtx.sampleRate * ((arr[i][0]-currCycles)/freq));
        for (let j = 0; j < frameCount; j++) {
          buffer.push(prevDacValue/512-1);
        }
        prevDacValue = arr[i][1];
        currCycles = arr[i][0];
      }
      // Don't forget the last sample
      let frameCount = Math.round(audioCtx.sampleRate * ((e.data.cycles-currCycles)/freq));
      for (let j = 0; j < frameCount; j++) {
        buffer.push(prevDacValue/512-1);
      }
      // Translate to a Float32Array
      const bufferF32 = new Float32Array(buffer);
      // Create the audio buffer
      let myArrayBuffer = audioCtx.createBuffer(1, audioCtx.sampleRate*duration/1000, audioCtx.sampleRate);
      myArrayBuffer.copyToChannel(bufferF32, 0, 0);
      let source = audioCtx.createBufferSource();
      // set the buffer in the AudioBufferSourceNode
      source.buffer = myArrayBuffer;
      // connect the AudioBufferSourceNode to the
      // destination so we can hear the sound
      source.connect(gain).connect(audioCtx.destination);
      queueAudio(source);
      prevDacUpdate = now;
    } else if (e.data.type === 'ADC') {
      if (e.data.channel&0x2) { // light sensor
        cpu.postMessage({
          type: 'ADC',
          channel: 1,
          value: lightSensor
        });
      } else if (e.data.channel&0x4) { // potentiometer
        // console.log(potentiometer);
        cpu.postMessage({
          type: 'ADC',
          channel: 2,
          value: potentiometer
        });
      } else { // a channel I haven't implemented yet
        cpu.postMessage({
          type: 'ADC',
          channel: 10, // a channel that doesn't exist
          value: 0
        });
      }
    } else if (e.data.type === 'instructions') {
      this.updateInstructions({instructionList: e.data.instructionList});
    } else if (e.data.type === 'state') {
      this.updateInstructions(e.data.update);
      // console.log(e.data.update);
    } else if (e.data.type === 'LCD') {
      refreshLCD(e.data.queue);
    } else if (e.data.type === 'TSC') {
      // console.log(`Sending ${tscLastCalc}`);
      cpu.postMessage({
        type: 'TSC',
        data: tscLastCalc
      });
      tscLastCalc = tscCalculate(e.data.data);
    } else if (e.data.type === 'update-clk') {
      this.setState({
        clk: e.data.data
      });
    } else if (e.data.type === 'I2C') {
      if (e.data.flavour === 'START') {
        this.i2cStart();
      } else if (e.data.flavour === 'DATA') {
        if (i2c === -1) { // command byte with address and r/w
          this.i2cSetup(e.data.data);
        } else {
          this.i2cWrite(e.data.data);
        }
      } else if (e.data.flavour === 'STOP') {
        this.i2cStop();
      }
    } else if (e.data.type === 'error') {
      // Display an error
    }
  }

  i2cStart() {
    i2c = -1; // initial state
  }

  i2cSetup(value) {
    i2cAddress = value>>>1;
    i2c = value&0x1; // read/write
    if (i2cAddress === 0x60) { // LED dimmer
      LEDDimmerState = -1;
      cpu.postMessage({
        type: 'I2C',
        flavour: 'ACK'
      });
    }
  }

  i2cWrite(value) {
    if (i2cAddress === 0x60) { // LED dimmer
      this.LEDDimmer(value);
      cpu.postMessage({
        type: 'I2C',
        flavour: 'ACK'
      });
    }
  }

  i2cStop() {
    if (i2cAddress === 0x60) {
      LEDDimmerState = -1;
    }
  }

  LEDDimmer(value) {
    if (LEDDimmerState === -1) { // we are receiving the register
      LEDDimmerState = value&0x10; // auto increment
      LEDDimmerRegister = value&0xF;
    } else { // Actually doing something
      if (i2c === 0) { // read
        if (LEDDimmerRegister === 0x8) { // LEDs 1-4
          let newState = {};
          switch (value&0x3) {
            case 0:
              newState.LED1 = 1;
              break;
            case 1:
              newState.LED1 = 0;
              break;
            // TODO implement PWM
          }
          switch ((value&0xC)>>2) {
            case 0:
              newState.LED2 = 1;
              break;
            case 1:
              newState.LED2 = 0;
              break;
            // TODO implement PWM
          }
          switch ((value&0x30)>>4) {
            case 0:
              newState.LED3 = 1;
              break;
            case 1:
              newState.LED3 = 0;
              break;
            // TODO implement PWM
          }
          switch ((value&0xC0)>>6) {
            case 0:
              newState.LED4 = 1;
              break;
            case 1:
              newState.LED4 = 0;
              break;
            // TODO implement PWM
          }
          this.setState(newState);
        } else if (LEDDimmerRegister === 0x9) { // LEDs 5-8
          let newState = {};
          switch (value&0x3) {
            case 0:
              newState.LED5 = 1;
              break;
            case 1:
              newState.LED5 = 0;
              break;
            // TODO implement PWM
          }
          switch ((value&0xC)>>2) {
            case 0:
              newState.LED6 = 1;
              break;
            case 1:
              newState.LED6 = 0;
              break;
            // TODO implement PWM
          }
          switch ((value&0x10)>>4) {
            case 0:
              newState.LED7 = 1;
              break;
            case 1:
              newState.LED7 = 0;
              break;
            // TODO implement PWM
          }
          switch ((value&0xC0)>>6) {
            case 0:
              newState.LED8 = 1;
              break;
            case 1:
              newState.LED8 = 0;
              break;
            // TODO implement PWM
          }
          this.setState(newState);
        }
      } else { // read

      }
    }
  }

  runToCursor() {
    if (cpu && ! running) {
      cpu.postMessage({
        type: 'run-to-cursor',
        curr: currentCursor
      });
      running = true;
    }
    
  }

  step() {
    if (cpu && !running) {
      cpu.postMessage({
        type: 'step'
      });
    }
  }

  run() {
    if (cpu && !running) {
      running = true;
      cpu.postMessage({
        type: 'run'
      });
      if (audioCtx.state === 'suspended') {
        audioCtx.resume();
      }
    }
  }

  stop() {
    if (cpu && running) {
      running = false;
      cpu.postMessage({
        type: 'stop'
      });
      if(audioCtx.state === 'running') {
        audioCtx.suspend();
      }
    }
  }

  reset() {
    // I should probs also clear memory but ceebs
    // TODO: clear highlighted pc line
    running = false;
    bufferQueue = [];
    playing = false;
    audioCtx.close();
    audioCtx = new AudioContext();
    prevDacUpdate = undefined;
    prevDacValue = 0;
    if (cpu) {
      cpu.terminate();
      cpu = new Worker();
      cpu.onmessage = this.cpu_handler;
      // cpu.postMessage();
      if (!checkELF(elf_contents)) {
        console.log("Not an ELF file!");
        return;
      }
      cpu.postMessage({
        type: 'setup',
        file: elf_contents,
        sampleRate: audioCtx.sampleRate
      });
    }
  }

  goToReadme() {
    document.getElementById('help-link').click();
  }

  P0_10(value) {
    if (cpu) {
      cpu.postMessage({
        type: 'P0_10',
        value: value
      });
    }
  }

  P0_11(value) {
    if (cpu) {
      cpu.postMessage({
        type: 'P0_11',
        value: value
      });
    }
  }

  changeVolume(value) {
    gain.gain.value = (value*value*value)/25;
  }

  changePotentiometer(value) {
    potentiometer = Math.round(value*1023);
  }

  changeLightSensor(value) {
    lightSensor = Math.round(value*1023);
  }

  displayRegisters(regBank) {
    this.setState({
      d_R0: regBank[0],
      d_R1: regBank[1],
      d_R2: regBank[2],
      d_R3: regBank[3],
      d_R4: regBank[4],
      d_R5: regBank[5],
      d_R6: regBank[6],
      d_R7: regBank[7],
      d_R8: regBank[8],
      d_R9: regBank[9],
      d_R10: regBank[10],
      d_R11: regBank[11],
      d_R12: regBank[12],
      d_SP: regBank[13],
      d_LR: regBank[14],
      d_PC: regBank[15],
      d_CPSR: regBank[16],
      d_SPSR: regBank[17],
    })
  }

  uploadFile() {
    const fileElem = document.getElementById("fileUpload");
    fileElem.click();
  }

  async readFile() {
    let file = document.getElementById('fileUpload').files[0];
    let reader = new FileReader();
    reader.onload = (event) => {
      elf_contents = event.target.result;
      if (!checkELF(elf_contents)) {
        console.log("Not an ELF file!");
        return;
      }
      if (cpu) {
        running = false;
        cpu.terminate();
        audioCtx.close();
        audioCtx = new AudioContext();
        gain = audioCtx.createGain();
        gain.gain.value = 0.125/25;
        bufferQueue = [];
        playing = false;
      }
      cpu = new Worker();
      cpu.onmessage = this.cpu_handler;
      cpu.postMessage({
        type: 'setup',
        file: elf_contents,
        sampleRate: audioCtx.sampleRate
      });
      document.getElementById('fileUpload').value = '';
      // console.log('Message Posted to worker');
    };
    reader.onerror = function(event) {
        console.error("File could not be read! Code " + event.target.error.code);
    };
    reader.readAsBinaryString(file);
  }

  render() {
    let registers = [];
    for (let i = 0; i < 18; i++) {
      registers.push(<Register key={i} text={strReg[i]} value={this.state[`d_${strReg[i]}`]} />);
    }
    // TODO: make this a separate element/component
    // registers.push(<Register key={18} text='CLK' value={this.state.clk} />);
    let code = [];
    for (let i = 0; i < this.state.instructionList.length; i++) {
      code.push(<div key={i} id={'instr'+i} onClick={(e) => setCursor(e.target, this)}>{this.state.instructionList[i]}</div>);
    }
    return (
      <div className='nyansim-container'>
        <Helmet>
          <title>Jonah Meggs | NyanSim</title>
          <meta name="description" content="NyanSim is a LPC2478 simulator designed for the UNSW DESN2000 labs. 
        It interprets your executable (.axf), 
        and simulates what the actual QVGA board would be doing." />
        </Helmet>
        <div className='reg-qvga-container'>
          <div className='qvga-controls-container'>
            <input type="file" id="fileUpload" name="fileUpload" accept=".axf" style={{display:"none"}}/>
            <button id="fileSelect" onClick={this.uploadFile}>Select a file</button>
            <button id='reset' onClick={this.reset}>Reset</button>
            <button id='run' onClick={this.run}>Run</button>
            <button id='stop' onClick={this.stop}>Stop</button>
            <button id='step' onClick={this.step}>Step</button>
            <button id='run-to-cursor' onClick={this.runToCursor}>Run to cursor</button>
            <button id='help' onClick={this.goToReadme}>Help!</button>
            <a id='help-link' href='#nyansim-readme' style={{display: "none"}}></a>
          </div>
          <div className='registers'>
            {registers}
            <div className='register-container'>{`CLK:  ${this.state.clk}MHz`}</div>
          </div>
          <div className='qvga-flex-wrapper'>
            <QVGA ladder={this.state.ladder&this.state.ladderEn} P3_16={this.state.P3_16} P3_17={this.state.P3_17} P3_18={this.state.P3_18} P3_19={this.state.P3_19} P3_20={this.state.P3_20} P3_21={this.state.P3_21} P0_10={this.P0_10} P0_11={this.P0_11} changeVolume={this.changeVolume} changePotentiometer={this.changePotentiometer} LCDenable={this.state.LCDenable} LED1={this.state.LED1} LED2={this.state.LED2} LED3={this.state.LED3} LED4={this.state.LED4} LED5={this.state.LED5} LED6={this.state.LED6} LED7={this.state.LED7} LED8={this.state.LED8}/>
          </div>
          <LightSensor update={this.changeLightSensor}/>
        </div>
        <div className='assembly-code' id='assembly-code'>
          {code}
        </div>
        <h3 id='nyansim-readme'>Read Me!!</h3>
        <h4>What is NyanSim?</h4>
        <p>NyanSim is a LPC2478 simulator designed for the UNSW DESN2000 (rip 2142) labs. 
        It interprets your executable (.axf), and simulates what the actual QVGA board would be doing.</p>
        <h4>Sounds cool, how do I use it?</h4>
        <p>You'll need to first build a project in uVision, in order to get the .axf file. If it doesn't compile in uVision, the .axf file will not be created/updated. Once you have successfully built your project, click on "Select a file", navigate to the output folder in your project directory, and upload the .axf file. Congrats, your program has been loaded onto the virtual microcontroller!
        You can "Run" your program, "Stop" it and "Reset" it. NyanSim also offers primitive debugging features, including a disassembly window and "Step" and "Run to cursor" commands. The cursor is set by selecting a line in the disassembly window (it will appear blue). The instruction about to be executed is highlighted yellow.</p>
        <h4>Usage notes</h4>
        <p>Some things to keep in mind:</p>
        <ul>
          <li>NyanSim only works with ARM instructions, not Thumb. It will throw an error if you try to give it Thumb instructions. Make sure to disable Thumb instructions in uVision by going to "Project-{'>'}Options for Target", and <strong>disabling</strong> "Enable ARM/Thumb Interworking" in both the "C/C++" and "Asm" tabs. You'll need to build again after this.</li>
          <li>NyanSim runs slightly slower than the actual chip (see below for more info). The real QVGA board runs at 72MHz, but this simulator runs at about 10-40MHz (depending on your processor and browser).</li>
          <li>Some QVGA board functionalities are either not implemented, or partially implemented. See below for a full list of what is currently supported.</li>
          <li>The yellow highlighted line in the disassembly window is not the PC, but PC-8 (the instruction about to be executed). Note that on reset, the PC will start at 8.</li>
          <li>You may need to modify some parts of the labs to be compatible with the simulator. See below for details.</li>
        </ul>
        <h4>It's not working :-(</h4>
        <ul>
          <li>Ensure you have built your project, and it compiled successfully.</li>
          <li>Ensure you've read the usage notes above, particularly regarding Thumb instructions.</li>
        </ul>
        <p>If all else fails, there is the possibility it's a bug. Flick me an email via the contact page of this site, attaching a .zip of your project.</p>
        <h4>Why is it named NyanSim?</h4>
        <p>You'll work out why once you get your "play_song" project working :-)</p>
        <h4>Notes per lab</h4>
        <ul>
          <li>Lab 0: hexloader/demo won't work, as it is compiled into a .hex file rather than the .axf file that is used for the rest of the labs.</li>
          <li>Lab 4: you don't need to change anything, but for <strong>blinky</strong> note that the LEDs will blink about 2-3 times slower than they will on the real board.</li>
          <li>Lab 5: <strong>sine_wave</strong> project currently not supported, as you need to use an oscilloscope. For the <strong>play_song</strong> project, since you are using one of the timers on the QVGA board you will need to consider the difference in speed between the real board and your web browser. This will affect how you set up your prescale register. I recommend running the project with no modification in your browser, observing the clock speed achieved (under the registers), and setting up the prescale register appropriately.</li>
        </ul>
        <h4>Supported functionalities and peripherals</h4>
        <p>The more that are implemented, the slower the simulator becomes. Thus, some functionalities are only partially implemented as below. If you need a functionality for your project that is currently not implemented, please contact me via the email on the contact page to request that it be implemented.</p>
        <ul>
          <li>LED1-8 are supported. These are set over I2C, see note below on I2C.</li>
          <li>Everything on the daughterboard is fully supported - RGB LEDs, push buttons, LED ladder. Both buttons can be considered to be debounced.</li>
          <li>DAC and volume potentiometer are supported. Bias in the DACR is not implemented.</li>
          <li>Timer0 is implemented with all 4 match registers. Interrupts in the MCR are not supported. Timers 1-3 are not supported.</li>
          <li>ADC and potentiometer are supported. The ADC currently only supports channels AD0.2 (red potentiometer) and AD0.1 (light sensor on breakout board). Other channels are not supported. Burst mode is not supported. CLKDIV is not considered, since it takes considerable time for the cpu to request the channel value from the GUI.</li>
          <li>LCD screen is supported. The LCD screen is set up over SPI. See note below on SPI</li>
          <li>Touch screen is supported. Only 8-bit resolution with differential reference and power down between conversions is supported.</li>
          <li>I2C is implemented quite spartanly. The processor only works in master transmit mode, and there is only one slave - the led dimmer that drives LED1-8 and PB1-4. Because only master transmit is implemented, only the LEDs work.</li>
          <li>SPI is also implemented quite spartanly. The 2 SPI peripherals are the LCD screen and the touchscreen controller. SPI is used to set up the LCD screen in lcd_display_init(), and since this is code you will not modify, the simulator just assumes that you have set it up correctly. All SPI communication to the LCD screen is ignored. Whilst SPI is actually implemented for the touch screen controller, it only works with the S0SPCR configured as per Lab 6. Furthermore, SCLK and thus S0SPCCR are ignored.</li>
        </ul>
        <h4>If you're interested...</h4>
        <p>NyanSim is implemented in 2 halves - the frontend that presents the GUI to you that you interact with, and a background thread called a Web Worker that implements the LPC2478 processor. The reason these two are needed is because if I was to implement the cpu along with the GUI, the GUI would become quite unresponsive, as it would need to wait for the cpu to finish what it was doing before responding to your input (pushing a button etc). No one likes an unresponsive webpage!</p>
        <p>This actually works quite well, because it means that the cpu just continually executes instructions, sending messages to the GUI and responding to messages from the GUI as required, which is similar to how the communication protocols (SPI, I2C) work anyway! You might notice that when running NyanSim that an entire core of your computer is being hogged by a chrome resource - that's the Web Worker! It is working super hard to make the simulator as fast as possible.</p>
        <p>The first thing that happens is that your uploaded .axf file is parsed, and memory is initialised. It's worth noting that the .axf file produced by uVision is an ELF file, and so it has a standard format. You can see in the disassembly window above that I've been able to pull out some crude debugging info, such as function names.</p>
        <p>Memory cannot be implemented in its entirety - the LPC2478 uses 32-bit addresses, which means a 4GB address space. Imagine your chrome tab trying to use 4GB of memory! To get around this, memory is implemented as a sparse tree. The tree has 4 levels, and is implemented with arrays. Each node in the tree has 256 children. This means that I effectively split memory up into 256^3 = 16777216 blocks of 256 bytes, which are allocated only if they are used by your project. This also means that if you try to use memory that has not been initialised, the simulator crashes. Hopefully you are initialising your memory! (think DCD vibes)</p>
        <p>So how does the processor execute instructions? When parsing your .axf file, NyanSim grabs all your instructions (encoded), decodes them, and works out exactly what they are. Then, rather than storing an array with the machine or assembly code for the instructions, NyanSim stores an array of <i>functions</i>, where each function corresponds to a particular instruction (MOV, STR, ADD etc). And so you can see that NyanSim doesn't really do any pipelining, but rather just executes instruction after instruction by calling the function at the index in the PC-8, and then incrementing the PC. We can see that the speed of the processor is limited by how fast these instructions execute. On the LPC2478, the number of clock cycles per instruction is usually 1-3, but implementing these instructions as functions in JavaScript is nowhere near as fast. Consider what I have to do (sequentially) in JavaScript that happens concurrently in the LPC2478 for an ADD instruction:</p>
        <ul>
          <li>Check the condition flags in the CPSR and work out whether to continue executing the ADD</li>
          <li>Perform the addition</li>
          <li>Set the destination register with the result</li>
          <li>Potentially generate N, Z, C, V and update the CPSR if S is set in the instruction</li>
          <li>Increment the PC</li>
          <li>Increment Timer0 if enabled</li>
          <li>Check if Timer0 has matched any of the 4 match registers, and potentially do something if this is the case</li>
        </ul>
        <p>This all takes time, and so an average simulated clock speed of ~20MHz is actually quite impressive given what the simulated processor is actually doing. Above I mentioned that some functionalities are only partially implemented to avoid unnecessarily slowing down the processor. Imagine if I implemented Timers1-3 as well - every instruction we would now have to increment these timers as well as check if they had matched any of their respective match registers - expensive!</p>
        <p>One further point to mention is that both the DAC data and LCD screen data are buffered rather than being continuously sent to the GUI. Imagine if I didn't do this - every time your code sets a pixel in the LCD screen a message would be sent from the cpu worker to the GUI - 240*320 messages for a screen refresh! This would overload the GUI and be a performance bottleneck. So instead, the CPU buffers the data to be sent to both the LCD screen and the speaker, and sends it at 20ms and 500ms intervals respectively. This is why when using the speaker there is a discontinuity every 0.5s - this is the join between 2 speaker buffers.</p>
      </div>
    );
  }
}
 
export default NyanSim;